import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
export default {
  [Actions.AMAZON_EBAY_CHART_ADD_FILTER]({ commit }, filter) {
    commit(Mutations.SET_AMAZON_EBAY_FILTER, filter);
  },
  [Actions.AMAZON_EBAY_CHART_REMOVE_FILTER]({ commit }, filter) {
    commit(Mutations.SET_REMOVE_AMAZON_EBAY_FILTER, filter);
  }
};
