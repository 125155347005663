export const PROBLEM_TYPES = [
  { value: "Dolphin is not working", label: "Dolphin is not working" },
  { value: "Moves & Stops (Red light)", label: "Moves & Stops (Red light)" },
  {
    value: "Power Supply is not working (PWS LED's)",
    label: "Power Supply is not working (PWS LED's)"
  },
  { value: "Broken Cable", label: "Broken Cable" },
  { value: "Won't Climb", label: "Won't Climb" },
  { value: "Misses Spots", label: "Misses Spots" },
  { value: "Doesn't Sink", label: "Doesn't Sink" },
  { value: "Doesn't Clean", label: "Doesn't Clean" },
  { value: "Doesn't Move Properly", label: "Doesn't Move Properly" },
  { value: "Disengage from Wall", label: "Disengage from Wall" },
  { value: "Missing Parts", label: "Missing Parts" },
  { value: "Broken Parts", label: "Broken Parts" },
  { value: "Remote Control Issue", label: "Remote Control Issue" },
  {
    value: "Wave XL - Communication Issue",
    label: "Wave XL - Communication Issue"
  },
  { value: "Wave XL - Navigation Error", label: "Wave XL - Navigation Error" },
  { value: "Liberty - No Communication", label: "Liberty - No Communication" },
  { value: "Tracks Fell Off", label: "Tracks Fell Off" },
  { value: "Cable Tangling", label: "Cable Tangling" },
  { value: "Damage in Transport", label: "Damage in Transport" },
  { value: "Wheelie", label: "Wheelie" },
  { value: "Stuck (Drain/VGB)", label: "Stuck (Drain/VGB)" },
  { value: "Stuck (Other)", label: "Stuck (Other)" },
  { value: "Filter Basket/Cartridge", label: "Filter Basket/Cartridge" },
  { value: "Full Filter Indicator", label: "Full Filter Indicator" },
  { value: "Quality Issue Event", label: "Quality Issue Event" },
  {
    value: "Communication Issue (PWS to Robot)",
    label: "Communication Issue (PWS to Robot)"
  },
  { value: "Connectivity", label: "Connectivity" },
  { value: "Wave XL - Recovery Mode", label: "Wave XL - Recovery Mode" },
  {
    value: "Wave XL - Does Not Move Straight Lines",
    label: "Wave XL - Does Not Move Straight Lines"
  },
  {
    value: "Wave - Spinning Around Itself",
    label: "Wave - Spinning Around Itself"
  },
  {
    value: "Wave - Does Not Climb Slope",
    label: "Wave - Does Not Climb Slope"
  },
  {
    value: "Other Issue (Please Specify)",
    label: "Other Issue (Please Specify)"
  }
];
export const SERVICE_REQUEST_COURIER = [
  { value: "Star Track", label: "Star Track" },
  { value: "TNT", label: "TNT" },
  { value: "TOLL", label: "TOLL" }
];
export const SERVICE_REQUEST_INFO = {
  consumerInfo: {
    name: "",
    phone: "",
    email: "",
    address: {
      address: "",
      addressLine: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: ""
    }
  },
  deliveryInfo: {
    name: "",
    phone: "",
    email: "",
    address: {
      address: "",
      addressLine: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: ""
    }
  },
  model: "",
  serialNumber: "",
  dateDropOff: {
    time: "",
    date: ""
  },
  problem: "",
  inclusion: [],
  purchaseDate: "",
  imgUrl: []
};

export const SERVICE_REQUEST_PICKUP_INFO = {
  date: "",
  basicInfo: {
    name: "",
    contactName: "",
    phone: "",
    email: "",
    address: {
      address: "",
      addressLine: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: ""
    }
  },
  branch: {
    name: "",
    address: {
      address: "",
      addressLine: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: ""
    }
  },
  dimensions: {
    pickup_pallets: 0
  },
  shipping: {
    carrier_and_service: "",
    carrier_name: "",
    service_name: "",
    label_pdf: "",
    consignment: "",
    id: ""
  },
  pickup: {
    pickup: "",
    booked_for: ""
  },
  robots: [],
  notes: "",
  robotNotes: "",
  agreement: false,
  priorityId: "",
  status: "processing",
  dealer: null
};

export const SERVICE_REQUEST_MODEL_OPTIONS = [
  { label: "ACTIVE X6", value: "ACTIVE X6" },
  { label: "AW4", value: "AW4" },
  { label: "AW5", value: "AW5" },
  { label: "AW6", value: "AW6" },
  { label: "DB1", value: "DB1" },
  { label: "DB2", value: "DB2" },
  { label: "E10", value: "E10" },
  { label: "ECLIPSE XL CB", value: "ECLIPSE XL CB" },
  { label: "ECLIPSE XL WB", value: "ECLIPSE XL WB" },
  { label: "ECLIPSE XLR WB", value: "ECLIPSE XLR WB" },
  { label: "LIBERTY 400", value: "LIBERTY 400" },
  { label: "M400 CB", value: "M400 CB" },
  { label: "M400 WB", value: "M400 WB" },
  { label: "M500 CB", value: "M500 CB" },
  { label: "M500 WB", value: "M500 WB" },
  { label: "M600", value: "M600" },
  { label: "M700", value: "M700" },
  { label: "Pro X2 CB", value: "Pro X2 CB" },
  { label: "Pro X2 WB", value: "Pro X2 WB" },
  { label: "S50", value: "S50" },
  { label: "S100", value: "S100" },
  { label: "S150", value: "S150" },
  { label: "S200", value: "S200" },
  { label: "S250", value: "S250" },
  { label: "S400", value: "S400" },
  { label: "SWASH CL", value: "SWASH CL" },
  { label: "SWASH CLX CB", value: "SWASH CLX CB" },
  { label: "SWASH CLX WB", value: "SWASH CLX WB" },
  { label: "SWASH TCX CB", value: "SWASH TCX CB" },
  { label: "SWASH TCX WB", value: "SWASH TCX WB" },
  { label: "WAVE 150", value: "WAVE 150" },
  { label: "WAVE 200 XL", value: "WAVE 200 XL" },
  { label: "WAVE 300 XL 50M", value: "WAVE 300 XL 50M" },
  { label: "WAVE PRO EXPERT 2X2 CB", value: "WAVE PRO EXPERT 2X2 CB" },
  { label: "WAVE PRO EXPERT 2X2 WB", value: "WAVE PRO EXPERT 2X2 WB" },
  { label: "WAVE 100 CB", value: "WAVE 100 CB" },
  { label: "WAVE 100 WB", value: "WAVE 100 WB" },
  { label: "WAVE 20", value: "WAVE 20" },
  { label: "WAVE 75 CB", value: "WAVE 75 CB" },
  { label: "WAVE 75 WB", value: "WAVE 75 WB" },
  { label: "X30", value: "X30" },
  { label: "X40", value: "X40" }
];

export const SERVICE_REQUEST_BRANCH_OPTIONS = [
  { label: "QLD - Oxley", value: "OXLEY" },
  { label: "QLD - Townsville", value: "TOWNSVILLE" },
  { label: "NSW - Gregory Hills", value: "GREGORY HILLS" },
  { label: "NSW - Lidcombe", value: "LIDCOMBE" },
  { label: "VIC - Dandenong South", value: "DANDENONG SOUTH" },
  { label: "SA - Pooraka", value: "POORAKA" },
  { label: "WA - Forrestdale", value: "FORRESTDALE" }
];

export const DEFAULT_SERVICE_CENTER_OPTIONS = [
  {
    label: "Maytronics - Gregory Hills",
    assignedState: ["New South Wales", "Australian Capital Territory"],
    value: "Maytronics - NSW Gregory Hills",
    emails: ["Alan.Grix@Maytronics.com", "Timothy.Corderoy@Maytronics.com"],
    address: {
      address: "Unit 1, 45-47 Rodeo Road, Gregory Hills NSW 2557",
      addressLine: "Unit 1, 45-47 Rodeo Road",
      addressLine2: "",
      city: "Gregory Hills",
      state: "New South Wales",
      shortCode: "NSW",
      postalCode: "2557"
    },
    costCentre: "MAYTRONICSYD"
  },
  {
    label: "Maytronics - Danenong South",
    assignedState: ["Victoria", "Tasmania"],
    value: "Maytronics - VIC Dandenong South",
    emails: ["Steven.Flint@Maytronics.com", "Brendan.Marshall@Maytronics.com"],
    address: {
      address: "18 Babbage Drive Dandenong South Melbourne VIC 3175",
      addressLine: "18 Babbage Dr",
      addressLine2: "",
      city: "Dandenong South",
      state: "Victoria",
      shortCode: "VIC",
      postalCode: "3175"
    },
    costCentre: "MAYTRONICSMELB"
  },
  {
    label: "Maytronics - Oxley",
    assignedState: ["Queensland"],
    value: "Maytronics - QLD Oxley",
    emails: [
      "Leo.Cristobal@Maytronics.com",
      "JeanCarlos.Perido@Maytronics.com"
    ],
    address: {
      address: "2/91 Rudd Street (entry off Boundary Road), Oxley QLD 4075",
      addressLine: "2/91 Rudd St",
      addressLine2: "",
      city: "Oxley",
      state: "Queensland",
      shortCode: "QLD",
      postalCode: "4075"
    },
    costCentre: "MAYTRONICSAU"
  },
  {
    label: "Maytronics - Townsville",
    assignedState: ["Queensland", "Northern Territory"],
    value: "Maytronics - QLD Townsville",
    emails: ["Tyler.Kranz@Maytronics.com"],
    address: {
      address: "18/547 Woolcock Street Mount Louisa, QLD 4814, Australia",
      addressLine: "18/547 Woolcock St",
      addressLine2: "",
      city: "Mount Louisa",
      state: "Queensland",
      shortCode: "QLD",
      postalCode: "4814"
    },
    costCentre: "MAYTRONICSTWO"
  },
  {
    label: "Maytronics - Pooraka",
    assignedState: ["South Australia"],
    value: "Maytronics - SA Pooraka",
    emails: ["Cameron.Hawkins@Maytronics.com", "Cooper.Scott@maytronics.com"],
    address: {
      address: "6/1 Burma Road, Pooraka 5095, South Australia",
      addressLine: "6/1 Burma Rd",
      addressLine2: "",
      city: "Pooraka",
      state: "South Australia",
      shortCode: "SA",
      postalCode: "5095"
    },
    costCentre: "MAYTRONICSADL"
  },
  {
    label: "Maytronics - Forrestdale",
    assignedState: ["Western Australia"],
    value: "Maytronics - WA Forrestdale",
    emails: ["Henock.Kidanu@Maytronics.com"],
    address: {
      address: "3/4 Network Place, Forrestdale WA 6112",
      addressLine: "3/4 Network Pl",
      addressLine2: "",
      city: "Forrestdale",
      state: "Western Australia",
      shortCode: "WA",
      postalCode: "6112"
    },
    costCentre: "MAYTRONICSPTH"
  }
];

export const DEFAULT_SERVICE_REQUEST_PICKUP_STATUS = [
  { value: "processing", label: "Consignment Created" },
  { value: "booked", label: "Awaiting Pickup" },
  {
    value: "Received at Repair Facility",
    label: "Received at Facility"
  },
  { value: "Repair in Progress", label: "Repair in Progress" },
  { value: "Closed", label: "Closed" }
];
