import * as Firebase from "@/common/firebase.js";
import {
  collection,
  getDocs,
  orderBy,
  query,
  doc,
  setDoc
} from "firebase/firestore";
import Fuse from "fuse.js";
import { uploadFile, ref, storage } from "@/common/firebase";
import { convertArrayToCSV } from "convert-array-to-csv";
import { COMMERCIAL_CSV_FIELDS } from "../constants/commercial.constants";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";

const firestoreCollection = getFirestoreCollectionConfig();

export const getAllCustomers = async (dbCollection) => {
  const customer = await getDocs(
    query(
      collection(Firebase.db, `${dbCollection}`),
      orderBy("last_update", "desc")
    )
  );

  return customer.empty
    ? parseCustomerDoc(getDocs(collection(Firebase.db, `${dbCollection}`)))
    : parseCustomerDoc(customer);
};

export const parseCustomerDoc = (snapshot) => {
  const customers = [];
  snapshot.forEach((doc) => {
    customers.push({
      id: doc.id,
      data: doc.data()
    });
  });
  return customers;
};

export const customerSearchFilterHelper = (search, list) => {
  let filter = list;
  if (search !== "" && search !== null) {
    filter = new Fuse(list, fuseConfigCustomer)
      .search(search)
      .map((res) => res.item);
  }
  return filter;
};

export const fuseConfigCustomer = {
  keys: [
    {
      name: "data.pool_name",
      weight: 0.9
    },
    {
      name: "data.managed_by",
      weight: 0.8
    }
  ],
  includeScore: true,
  shouldSort: true,
  threshold: 0.15,
  findAllMatches: true
};

export const instantiateCustomerData = (state) => {
  if (!state.custId) {
    return (state.customer = null);
  }
  state.customer = Object.assign(
    {},
    state.allCustomers.find((p) => String(p.id) === state.custId).data
  );
  return state.customer;
};

export const saveCustomer = async (id, changes) => {
  const ref = doc(Firebase.db, firestoreCollection.commercial, `${id}`);
  await setDoc(ref, changes, { merge: true });
};

const getFormattedDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = now.getFullYear();
  return `${day}${month}${year}`;
};

export const handleInputChange = async (e, currentUser) => {
  const name = currentUser ? currentUser.split(" ")[0] : "";
  const salesAppRef = ref(
    storage,
    `${name ? name + "_" : ""}${
      getFormattedDate() +
      "_" +
      Math.floor(Math.random() * 99999999).toString() +
      e.target.files[0].name.replace(/\s+/g, "-").toLowerCase()
    }`
  );
  return uploadFile(salesAppRef, e.target.files[0]);
};

/**
 * Creates array for CSV format/Flattens customer data
 *
 * @param {number} targetIndex - reference index where the property will be added
 * @param {object} propertyData - key value pair you want to add to the current object
 * @param {Array} container - reference array where all the values will be placed
 * @param {String} propertyKey - target data in the propertyData
 * @param {String} newPropertyKey - the label of the old propertyKey for CSV purposes
 * @param {Object} objTemplate - the template for each value of the customer csv array
 * @returns {Object} e.g { FIELD1: "", FIELD2: ""}
 */
export const flattenCustomerData = (
  targetIndex,
  propertyData,
  container,
  propertyKey,
  newPropertyKey,
  objTemplate
) => {
  let data = propertyData[propertyKey];
  if (Array.isArray(data) && data.length !== 0) {
    data = data.map((item) => item.url).join(",\n");
  }
  // Check if the data is an object
  if (typeof data === "object") {
    data = data.value === "Other" ? `${data.value}: ${data.notes}` : data.value;
  }
  // Check if the container already has an existing value
  if (container[targetIndex]) {
    // If there is, then assign the new property in the existing customer object
    container[targetIndex][newPropertyKey] = data;
  } else {
    /**
     * If there is none, then add new data to the array with the objTemplate.
     * This is considered as a new object field that belongs to the previous customer
     *  */
    const tempD = {
      ...objTemplate,
      [newPropertyKey]: data
    };
    container[targetIndex] = tempD;
  }
};

/**
 * converts the COMMERCIAL_CSV_CONSTANTS nested fields to a single object.
 * @constructor
 * @returns {Object} e.g { FIELD1: "", FIELD2: ""}
 */
function prepareCsvTemplate() {
  return Object.keys(COMMERCIAL_CSV_FIELDS)
    .map((field) => {
      return Object.keys(COMMERCIAL_CSV_FIELDS[field]).map((fieldInner) => ({
        [COMMERCIAL_CSV_FIELDS[field][fieldInner]]: ""
      }));
    })
    .flat()
    .reduce((r, c) => Object.assign(r, c), {});
}

export const parseCustomerToCsvFormatSelected = (customers) => {
  const singleDataTemplate = {
    NAME: "",
    EMAIL: "",
    PHONE: "",
    "MANAGED BY": "",
    "CREATED BY": "",
    "POOL NAME": "",
    ...prepareCsvTemplate()
  };
  const formattedCustomers = [];

  customers.forEach((customer) => {
    formattedCustomers.push({
      ...singleDataTemplate,
      NAME: customer.contact_name,
      EMAIL: customer.contact_email,
      PHONE: customer.contact_phone,
      "MANAGED BY": customer.managed_by,
      "CREATED BY": customer.created_by,
      "POOL NAME": customer.pool_name
    });

    const currLengthCopy = formattedCustomers.length;
    Object.keys(COMMERCIAL_CSV_FIELDS).forEach((fields) => {
      if (customer[fields]) {
        customer[fields].forEach((dH, idxDh) => {
          const keyValues = COMMERCIAL_CSV_FIELDS[fields];
          Object.keys(dH).forEach((dHKey) => {
            flattenCustomerData(
              currLengthCopy + idxDh - 1,
              dH,
              formattedCustomers,
              dHKey,
              keyValues[dHKey],
              idxDh === 0
                ? formattedCustomers[formattedCustomers.length - 1]
                : singleDataTemplate
            );
          });
        });
      }
    });
  });

  return formattedCustomers;
};

export const downloadCustomerCSV = (data, filename) => {
  const csvString = convertArrayToCSV(data);
  const csvData = new Blob([csvString], { type: "text/csv" });
  var csvUrl = URL.createObjectURL(csvData);
  const filename_with_ext = filename + ".csv";

  const link = document.createElement("a");
  link.setAttribute("href", csvUrl);
  link.setAttribute("download", filename_with_ext);
  link.click();
};

export const customerSortHelper = (state, list) => {
  let filter = list;
  if (state.order !== null) {
    const { param, toggle } = state.order;
    if (toggle) {
      filter = filter.sort((a, b) =>
        a.data[param].localeCompare(b.data[param])
      );
    } else {
      filter = filter
        .sort((a, b) => a.data[param].localeCompare(b.data[param]))
        .reverse();
    }
  }
  return filter;
};
