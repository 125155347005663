import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import {
  getNotification,
  updateNotificationRead,
  publishAnnouncement
} from "@/store/helpers";
import { publishAnnouncementAbly } from "@/utils/ably";
import { getAuth } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import MODULE from "@/store/constants/module.constants";

export default {
  async [Actions.GET_NOTIFICATION]({ commit }, payload) {
    try {
      const { data } = await getNotification(payload);
      commit(Mutations.UPDATE_NOTIFICATIONS, data.notification);
    } catch (error) {
      commit(Mutations.SET_NOTIFICATION_ERROR, error);
    }
  },
  async [Actions.UPDATE_NOTIFICATION_READ]({ commit }, payload) {
    try {
      const data = await updateNotificationRead(payload);
      commit(Mutations.REMOVE_NOTIFICATION, data.id);
    } catch (error) {
      commit(Mutations.SET_NOTIFICATION_ERROR, error);
    }
  },
  async [Actions.PUBLISH_ANNOUNCEMENT]({ commit, dispatch }, payload) {
    try {
      const data = await publishAnnouncement(payload);
      if (data.success) {
        publishAnnouncementAbly([data.data]);
        const currentUser = getAuth()?.currentUser;
        await dispatch(Actions.LOG_EVENT, {
          user: currentUser.email,
          action: LOG_ACTIONS.PUBLISH_ANNOUNCEMENT,
          module: MODULE.NOTIFICATION,
          target: JSON.stringify({
            ...data.data
          }),
          datePerformed: serverTimestamp()
        });
        return { success: true };
      } else {
        throw new Error("Failed to publish announcement");
      }
    } catch (error) {
      commit(Mutations.SET_NOTIFICATION_ERROR, error);
      return { success: false };
    }
  }
};
