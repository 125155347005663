import axios from "axios";

const url =
  "https://ap-southeast-2.aws.data.mongodb-api.com/app/mtau-web-app-esnki/endpoint/Dealers/getAllDealers";
const orderUrl =
  "https://ap-southeast-2.aws.data.mongodb-api.com/app/mtau-web-app-esnki/endpoint/maytronics_get_orders_by_id";
const dealerUrl =
  "https://ap-southeast-2.aws.data.mongodb-api.com/app/mtau-web-app-esnki/endpoint/find_dealer_by_loc";
const dealerCreateUrl = "https://eople8zl3lxxvyq.m.pipedream.net";
const counterUrl =
  "https://ap-southeast-2.aws.data.mongodb-api.com/app/mtau-web-app-esnki/endpoint/getCounters";
const updateCounterUrl =
  "https://ap-southeast-2.aws.data.mongodb-api.com/app/mtau-web-app-esnki/endpoint/updateAffiliateCounters";
const baseMongoEndpointUrl =
  "https://ap-southeast-2.aws.data.mongodb-api.com/app/mtau-web-app-esnki/endpoint";

export const GET_ALL_DEALERS_FROM_MONGO = axios.create({
  baseURL: url,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

export const GET_ORDER_BY_ID_FROM_MONGO = axios.create({
  baseURL: orderUrl,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

export const GET_DEALER_BY_LOC = axios.create({
  baseURL: dealerUrl,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

export const CREATE_DEALER = axios.create({
  baseURL: dealerCreateUrl,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

export const GET_COUNTER = axios.create({
  baseURL: counterUrl,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

export const UPDATE_AFF_COUNTER = axios.create({
  baseURL: updateCounterUrl,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

const BASE_MONGO_FUNCTION_ENDPOINT = axios.create({
  baseURL: baseMongoEndpointUrl,
  headers: {
    "Content-type": "application/json"
  },
  params: {
    secret: process.env.VUE_APP_AFF_SECRET
  }
});

export const sendOrderEmailEndpoint = (orderId) =>
  BASE_MONGO_FUNCTION_ENDPOINT.post("/sendOrderEmail", { orderId });
export const sendOrderPriorityEndpoint = (orderId) =>
  BASE_MONGO_FUNCTION_ENDPOINT.post(
    "/sendOrderPriority",
    { orderId },
    { timeout: 120000 }
  );
export const checkSentRev = () =>
  BASE_MONGO_FUNCTION_ENDPOINT.post("/checkSentRev", {}, { timeout: 120000 });

export const posEndPoint = (payload) => {
  if (process.env.NODE_ENV === "production") {
    return BASE_MONGO_FUNCTION_ENDPOINT.post("/pos", payload, {
      timeout: 60000
    });
  } else {
    return BASE_MONGO_FUNCTION_ENDPOINT.post("/pos_dev", payload, {
      timeout: 60000
    });
  }
};
