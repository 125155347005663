import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_ALL_BRANCHES](state, payload) {
    state.branches = payload;
  },
  [Mutations.UPDATE_PERSONNEL](state, payload) {
    state.personnel = payload;
  },
  [Mutations.ADD_VISITOR](state, payload) {
    state.visitors.push(payload);
  },
  [Mutations.SET_ALL_VISITORS](state, payload) {
    state.visitors = payload.visitors;
    state.count = payload.count;
  },
  [Mutations.UPDATE_VISITOR](state, payload) {
    const index = state.visitors.findIndex(
      (visitor) => visitor.visitorId === payload.id
    );
    state.visitors[index] = payload;
  },
  [Mutations.SET_VISITOR](state, payload) {
    state.visitor = payload;
  },
  [Mutations.UPDATE_ALL_VISITOR](state, payload) {
    state.visitors = state.visitors.filter(
      (visitor) => visitor.visitorId !== payload
    );
  },
  [Mutations.PUSH_VISITOR_FILTER](state, { type, value }) {
    state.filters.push([type, value]);
  },
  [Mutations.DELETE_VISITOR_FILTER](state, payload) {
    state.filters = state.filters.filter(
      (filter) => filter[0] !== payload[0] || filter[1] !== payload[1]
    );
  },
  [Mutations.RESET_VISITOR_FILTERS](state) {
    state.filters = [];
  },
  [Mutations.SET_SEARCH_VISITORS](state, payload) {
    state.searchString = payload;
  },
  [Mutations.SET_VISITOR_ERROR](state, payload) {
    state.error = payload;
  },
  [Mutations.SET_SORT_VISITORS](state, payload) {
    state.sortBy = payload;
  },
  [Mutations.SET_VISITOR_TO_DELETE](state, payload) {
    state.toDelete = payload;
  },
  [Mutations.SET_VISITOR_LOADING](state, loading) {
    state.loading = loading;
  },
  [Mutations.SET_VISITOR_TODAY](state, { data }) {
    state.visitorToday = data;
  }
};
