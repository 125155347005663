import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import * as Firebase from "@/common/firebase.js";
import * as Helpers from "@/store/helpers";
import { NOTIFY } from "@/store/constants/notify.constants";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  serverTimestamp
} from "firebase/firestore";
import {
  getAllOrders,
  getOrder,
  notifyPriorityRequester,
  updateOrder,
  sendOrderInvoice,
  sendOrderMarketplaceInvoice,
  notifyDealerCancellation
} from "../../common/salesAppApi";
import {
  checkSentRev,
  sendOrderEmailEndpoint,
  sendOrderPriorityEndpoint
} from "../../common/mongo";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import { getAuth } from "firebase/auth";
import MODULE from "@/store/constants/module.constants";
import { loadAvatar } from "@/utils/avatarLoader";
import { objectCompare } from "@/utils/objectComparator";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";

const firestoreCollection = getFirestoreCollectionConfig();

export default {
  [Actions.CREATE_ORDER]: async ({ commit, dispatch }, data) => {
    const { id } = await addDoc(
      collection(Firebase.db, firestoreCollection.marketplace),
      data
    );
    const user = getAuth().currentUser;
    await dispatch(Actions.LOG_EVENT, {
      user: user.email,
      action: LOG_ACTIONS.CREATE_ORDER,
      module: MODULE.ONLINE_STORE,
      target: JSON.stringify({ id, reference: data.reference }),
      datePerformed: serverTimestamp()
    });
    commit(Mutations.SET_ORDER, { id: id, store: data.store, data: data });
  },
  [Actions.UPDATE_ORDER]: async ({ commit, dispatch, rootState }, { data }) => {
    try {
      if (data.showLoading) {
        commit(Mutations.SET_ORDER_ERROR, null);
        commit(Mutations.SET_ORDER_LOADING, true);
        commit(Mutations.SET_ORDER_LOADING_MESSAGE, "Updating Order Status");
      }

      const prevData = rootState.orders.orders.find(
        (item) => data.id === item.id
      );

      await setDoc(
        doc(Firebase.db, firestoreCollection.marketplace, data.id),
        { ...data.changes },
        { merge: true }
      );

      commit(Mutations.SET_UPDATE_ORDER, data);

      const newData = rootState.orders.orders.find(
        (item) => data.id === item.id
      );

      const user = getAuth().currentUser;
      await dispatch(Actions.LOG_EVENT, {
        user: user.email,
        action: LOG_ACTIONS.UPDATE_ORDER_FROM_FIRESTORE,
        module: MODULE.ONLINE_STORE,
        target: JSON.stringify({ id: data.id, reference: data.reference }),
        changes: JSON.stringify(objectCompare(prevData, newData)),
        datePerformed: serverTimestamp()
      });
      if (data.showLoading) {
        commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
        commit(Mutations.SET_ORDER_LOADING, false);
      }
    } catch (error) {
      commit(Mutations.SET_ORDER_ERROR, error);
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
      commit(Mutations.SET_ORDER_LOADING, false);
    }
  },
  async [Actions.GET_ALL_ORDERS]({ commit, dispatch, rootState }) {
    try {
      const orders = await Helpers.getAllOrders(
        firestoreCollection.marketplace
      );
      let userState = rootState.auth.users;
      if (userState.length === 0) {
        await dispatch(Actions.GET_ALL_USERS);
        userState = rootState.auth.users;
      }
      const ordersWithAvatar = loadAvatar(
        userState,
        orders,
        ["email"],
        ["data", "user"]
      );
      commit(Mutations.SET_ALL_ORDERS, [...ordersWithAvatar]);
      commit(Mutations.SET_ORDER_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_ORDER_LOADING, false);
      commit(Mutations.SET_ORDER_ERROR, error);
    }
  },
  [Actions.ORDER_ADD_FILTER]({ commit }, filter) {
    commit(Mutations.ADD_ORDER_FILTER, filter);
  },
  [Actions.ORDER_REMOVE_ALL_FILTER]({ commit }) {
    commit(Mutations.REMOVE_ALL_ORDER_FILTER);
  },
  [Actions.SEARCH_ORDERS]({ commit }, search) {
    commit(Mutations.SET_ORDERS_SEARCH, search);
  },
  [Actions.ORDER_REMOVE_FILTER]({ commit }, filter) {
    commit(Mutations.REMOVE_ORDER_FILTER, filter);
  },
  [Actions.ORDER_SAVE_SELECTED]({ commit }, selected) {
    commit(Mutations.SET_ORDER_SELECTED, selected);
  },
  async [Actions.GET_ORDERS_FROM_MONGO]({ commit }, payload) {
    commit(Mutations.SET_ORDER_LOADING, true);
    const { data } = await getAllOrders(payload);
    commit(Mutations.SET_ORDER_COUNT, data.count);
    commit(Mutations.SET_ALL_ORDERS_FROM_MONGO, data.orders);
    commit(Mutations.SET_ORDER_LOADING, false);
  },
  async [Actions.GET_SINGLE_ORDER_FROM_MONGO]({ commit }, { orderId }) {
    commit(Mutations.SET_ORDER_LOADING, true);
    const { data } = await getOrder(orderId);
    commit(Mutations.SET_ORDER_FROM_MONGO, data.order);
    commit(Mutations.SET_ORDER_LOADING, false);
  },
  async [Actions.SEND_ORDER_NOTIFY](
    { commit, dispatch },
    { orderId, type, requesterEmail }
  ) {
    try {
      commit(Mutations.SET_ORDER_ERROR, null);
      commit(Mutations.SET_ORDER_LOADING, true);
      let response = null;
      switch (type) {
        case NOTIFY.EMAIL:
          commit(Mutations.SET_ORDER_LOADING_MESSAGE, "Sending Order Email");
          response = await sendOrderEmailEndpoint(orderId);
          await dispatch(Actions.LOG_EVENT, {
            user: getAuth().currentUser.email,
            action: LOG_ACTIONS.SEND_ORDER_EMAIL,
            module: MODULE.ORDER,
            target: JSON.stringify({ id: orderId }),
            datePerformed: serverTimestamp()
          });
          break;
        case NOTIFY.PRIORITY:
          commit(
            Mutations.SET_ORDER_LOADING_MESSAGE,
            "Sending order priority. This may take awhile, please wait."
          );
          response = await sendOrderPriorityEndpoint(orderId);
          await notifyPriorityRequester({
            email: requesterEmail,
            orderId: orderId
          });
          await dispatch(Actions.LOG_EVENT, {
            user: getAuth().currentUser.email,
            action: LOG_ACTIONS.SEND_PRIORITY,
            module: MODULE.ORDER,
            target: JSON.stringify({ id: orderId, name: requesterEmail }),
            datePerformed: serverTimestamp()
          });
          break;
      }
      if (response) {
        const { data } = response;
        if (data.status === "success") {
          commit(Mutations.UPDATE_ORDER_FROM_LIST, data.order);
          commit(Mutations.SET_ORDER_FROM_MONGO, data.order);
          commit(Mutations.SET_ORDER_LOADING, false);
          commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
      commit(Mutations.SET_ORDER_ERROR.error);
      commit(Mutations.SET_ORDER_LOADING, true);
    }
  },
  async [Actions.SEND_ORDER_INVOICE](
    { commit, dispatch },
    { orderId, sendTo, sendToCc }
  ) {
    try {
      commit(Mutations.SET_ORDER_ERROR, null);
      commit(Mutations.SET_ORDER_LOADING, true);
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "Sending Order Invoice");
      await sendOrderInvoice({ orderId, email: sendTo, cc: sendToCc });
      await dispatch(Actions.LOG_EVENT, {
        user: getAuth().currentUser.email,
        action: LOG_ACTIONS.SEND_ORDER_INVOICE,
        module: MODULE.ORDER,
        target: JSON.stringify({ id: orderId, email: sendTo, cc: sendToCc }),
        datePerformed: serverTimestamp()
      });
      commit(Mutations.SET_ORDER_LOADING, false);
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
    } catch (error) {
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
      commit(Mutations.SET_ORDER_ERROR.error);
      commit(Mutations.SET_ORDER_LOADING, false);
    }
  },
  async [Actions.SEND_ORDER_MARKETPLACE_INVOICE](
    { commit, dispatch },
    { refId, sendTo, sendToCc }
  ) {
    try {
      commit(Mutations.SET_ORDER_ERROR, null);
      commit(Mutations.SET_ORDER_LOADING, true);
      commit(
        Mutations.SET_ORDER_LOADING_MESSAGE,
        "Sending Order Marketplace Invoice"
      );
      await sendOrderMarketplaceInvoice({ refId, email: sendTo, cc: sendToCc });
      const user = getAuth().currentUser;
      await dispatch(Actions.LOG_EVENT, {
        user: user.email,
        action: LOG_ACTIONS.SEND_ORDER_INVOICE,
        module: MODULE.ONLINE_STORE,
        target: JSON.stringify({
          reference: refId,
          email: sendTo,
          cc: sendToCc
        }),
        datePerformed: serverTimestamp()
      });
      commit(Mutations.SET_ORDER_LOADING, false);
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
    } catch (error) {
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
      commit(Mutations.SET_ORDER_ERROR, error);
      commit(Mutations.SET_ORDER_LOADING, true);
    }
  },
  async [Actions.UPDATE_ORDER_FROM_MONGO]({ commit, dispatch }, { order }) {
    try {
      const { orderId, dealer, sendDealerCancellation, oldDealer } = order;
      commit(Mutations.SET_ORDER_ERROR, null);
      commit(Mutations.SET_ORDER_LOADING, true);
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "Updating Order");
      const response = await updateOrder({ orderId, dealer });
      if (sendDealerCancellation) {
        await notifyDealerCancellation({
          orderId,
          dealer: oldDealer,
          currentUser: getAuth().currentUser.email
        });
      }
      await dispatch(Actions.LOG_EVENT, {
        user: getAuth().currentUser.email,
        action: LOG_ACTIONS.UPDATE_ORDER,
        module: MODULE.ORDER,
        target: JSON.stringify({ id: orderId }),
        datePerformed: serverTimestamp()
      });
      if (response) {
        const { data } = response;
        if (data.success) {
          commit(Mutations.UPDATE_ORDER_FROM_LIST, data.order);
          commit(Mutations.SET_ORDER_FROM_MONGO, data.order);
          commit(Mutations.SET_ORDER_LOADING, false);
          commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
      commit(Mutations.SET_ORDER_ERROR, error);
      commit(Mutations.SET_ORDER_LOADING, true);
    }
  },
  async [Actions.CHECK_SENT]({ commit, dispatch }) {
    try {
      commit(Mutations.SET_ORDER_ERROR, null);
      commit(Mutations.SET_ORDER_LOADING, true);
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "Running Check Sent");
      const res = await checkSentRev();
      await dispatch(Actions.LOG_EVENT, {
        user: getAuth().currentUser.email,
        action: LOG_ACTIONS.CHECK_SENT,
        module: MODULE.ORDER,
        target: JSON.stringify(res.data.result),
        datePerformed: serverTimestamp()
      });
      commit(Mutations.SET_ORDER_LOADING, false);
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
      return res.data;
    } catch (error) {
      commit(Mutations.SET_ORDER_LOADING_MESSAGE, "");
      commit(Mutations.SET_ORDER_ERROR, error);
      commit(Mutations.SET_ORDER_LOADING, true);
    }
  }
};
