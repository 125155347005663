import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_ERROR](state, error) {
    state.isAuthenticated = false
    state.errors = error;
  },
  [Mutations.SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = "";
  },
  [Mutations.PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = "";
  },
  [Mutations.SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [Mutations.SET_USERS](state, users) {
    state.users = users;
  },
  [Mutations.INSERT_USER](state, user) {
    state.users.unshift(user);
  }
};
