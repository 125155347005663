import {
  collection,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
  addDoc
} from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
const firestoreCollection = getFirestoreCollectionConfig();

export const getNotification = async (user) => {
  const snapshot = await getDocs(
    collection(Firebase.db, firestoreCollection.notification)
  );
  const notification = parseBranchDoc(snapshot, user);
  return {
    data: {
      notification
    }
  };
};

const parseBranchDoc = (snapshot, user) => {
  const notification = [];
  snapshot.forEach((doc) => {
    if (doc.data().read.includes(user)) {
      //read
    } else {
      notification.push({
        id: doc.id,
        ...doc.data()
      });
    }
  });
  return notification;
};

export const updateNotificationRead = async ({ notificationId, user }) => {
  const docRef = doc(
    Firebase.db,
    firestoreCollection.notification,
    notificationId
  );
  await updateDoc(docRef, { read: arrayUnion(user) });
  return { id: notificationId };
};

export const publishAnnouncement = async (payload) => {
  try {
    const querySnapshot = await getDocs(
      collection(Firebase.db, firestoreCollection.notification)
    );
    let notificationDoc;
    querySnapshot.forEach((doc) => {
      notificationDoc = doc;
    });

    if (notificationDoc) {
      const docRef = doc(
        Firebase.db,
        firestoreCollection.notification,
        notificationDoc.id
      );
      await updateDoc(docRef, payload);
      return { data: { id: docRef.id, ...payload }, success: true };
    } else {
      const docRef = await addDoc(
        collection(Firebase.db, firestoreCollection.notification),
        payload
      );
      return { data: { id: docRef.id, ...payload }, success: true };
    }
  } catch (error) {
    return { success: false };
  }
};
