export default {
  ADMIN: "admin",
  ORDER: "order",
  ONLINE_STORE: "online_store",
  COMMERCIAL: "commercial",
  DEALER: "dealers",
  ACTIVITY_LOGS: "activity_logs",
  PAYMENTS: "payments",
  AUTH: "auth",
  VISITOR: "visitor",
  REVENUE: "revenue",
  POS: "pos",
  NOTIFICATION: "notification",
  SERVICE_REQUEST: "service_request"
};
