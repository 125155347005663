const config = {
  development: {
    dealers: "masterListDealers",
    commercial: "commercial_customer_dev",
    marketplace: "orders_dev",
    visitor: "visitor_logs_dev",
    user_logs: "user_logs_dev",
    account_register: "account_register_dev",
    users: "users_dev",
    notification: "notification_dev",
    revenue: "revenue_dev",
    sparePartsRevenue: "spare_parts_revenue_dev",
    serviceRequestPickUp: "service_request_pickup_dev"
  },
  production: {
    dealers: "masterListDealers",
    commercial: "commercial_customers",
    marketplace: "orders",
    visitor: "visitor_logs",
    user_logs: "user_logs",
    account_register: "account_register",
    users: "users",
    notification: "notification",
    revenue: "revenue",
    sparePartsRevenue: "spare_parts_revenue",
    serviceRequestPickUp: "service_request_pickup"
  }
};

export default function getFirestoreCollectionConfig() {
  if (process.env.NODE_ENV === "production") {
    return config.production;
  }

  return config.development;
}
