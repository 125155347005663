export default {
  allBranches(state) {
    return state.branches;
  },
  getPersonnel(state) {
    return state.personnel;
  },
  allVisitors(state) {
    return state.visitors;
  },
  allSignedInVisitors(state) {
    return state.visitors.filter(
      (visitor) => visitor.timeIn && !visitor.timeOut
    );
  },
  getVisitor(state) {
    return state.visitor;
  },
  allVisitorsToday(state) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return state.visitors.filter((visitor) => {
      const timeInDate = new Date(
        visitor.timeIn._seconds * 1000 + visitor.timeIn._nanoseconds / 1000000
      );
      timeInDate.setHours(0, 0, 0, 0);
      return timeInDate.getTime() === today.getTime();
    });
  },
  getVisitorError(state) {
    return state.error;
  },
  VisitorToDelete(state) {
    return state.toDelete;
  },
  visitorLoading(state) {
    return state.loading;
  },
  getVisitorCount(state) {
    return state.count;
  },
  getTodayVisitor(state) {
    return state.visitorToday;
  }
};
