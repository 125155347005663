<template>
  <div class="relative rubick">
    <SnackBar ref="snackbar" />
    <router-view v-if="!isMaintenance" />
    <Maintenance v-else />
  </div>
</template>

<script>
import SnackBar from "./components/Shared/SnackBar.vue";
import Maintenance from "@/components/AdminModule/View/Maintenance.vue";
export default {
  components: {
    SnackBar,
    Maintenance
  },
  computed: {
    isMaintenance() {
      return process.env.VUE_APP_MAINTENANCE === "yes" ? true : false;
    }
  },
  mounted() {
    this.$root.snackbar = this.$refs.snackbar;
    document.documentElement.className = `${
      localStorage.getItem("theme") || "default"
    } ${(localStorage.getItem("appearance-mode") && "dark") || ""}`;
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overscroll-behavior: none;
  min-height: 100vh;
}

html.dark {
  ::-webkit-scrollbar-track {
    background: theme("colors.gray.800");
  }

  ::-webkit-scrollbar-thumb {
    background-color: theme("colors.gray.600");
    border: 3px solid theme("colors.gray.800");
  }

  * {
    scrollbar-color: theme("colors.gray.600") theme("colors.gray.800");
  }
}
</style>
