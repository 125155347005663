import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_AMAZON_EBAY_FILTER](state, filter) {
    state.filters = filter;
  },
  [Mutations.SET_REMOVE_AMAZON_EBAY_FILTER](state, filter) {
    state.filters = filter;
  },
  [Mutations.SET_AMAZON_EBAY_LOADING](state, loading) {
    state.loading = loading;
  }
};
