import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_ALL_DEALERS](state, dealers) {
    state.dealers = dealers;
  },
  [Mutations.SET_DEALER_ERROR](state, error) {
    state.errors = error;
  },
  [Mutations.SET_DEALER_LOADING](state, loading) {
    state.loading = loading;
  },
  [Mutations.SET_DEALER_SELECTED](state, selected) {
    state.selected = selected;
  },
  [Mutations.ADD_DEALER_FILTER](state, filter) {
    state.filters = [...state.filters, filter];
  },
  [Mutations.REMOVE_DEALER_FILTER](state, [field, value]) {
    state.filters = state.filters.filter(
      ([f, v]) => `${f}:${v}` !== `${field}:${value}`
    );
  },
  [Mutations.SET_DEALER_ORDER](state, order) {
    state.order = order;
  },
  [Mutations.SET_DEALERS_SEARCH](state, search) {
    state.search = search;
  },
  [Mutations.REMOVE_ALL_DEALER_FILTER](state) {
    state.filters = [];
  },
  [Mutations.SET_SALES](state, sales) {
    state.sales = sales;
  },
  [Mutations.SET_UPDATED_DEALER](state, dealer) {
    state.dealers = state.dealers.map((item) =>
      item.id === dealer.id && item.state === dealer.state
        ? { id: dealer.id, data: dealer.data, state: dealer.state }
        : item
    );
  },
  [Mutations.SET_DEALER_AMAZON_EBAY_FILTER](state, filter) {
    state.amazon_ebay_filter = filter;
  },
  [Mutations.SET_DEALER_REMOVE_AMAZON_EBAY_FILTER](state, filter) {
    state.amazon_ebay_filter = filter;
  },
  [Mutations.SET_CREATED_DEALER](state, dealer) {
    state.dealers = state.dealers.map((item) =>
      item.id === dealer.mongoId && item.state === dealer.state
        ? { id: dealer.mongoId, data: dealer, state: dealer.state }
        : item
    );
  }
};
