import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_ORDER_ERROR](state, error) {
    state.errors = error;
  },
  [Mutations.SET_ALL_ORDERS](state, orders) {
    state.orders = orders;
  },
  [Mutations.SET_ORDER_LOADING](state, loading) {
    state.loading = loading;
  },
  [Mutations.SET_ORDER](state, order) {
    state.orders = [...state.orders, order];
  },
  [Mutations.ADD_ORDER_FILTER](state, filter) {
    state.filters = [...state.filters, filter];
  },
  [Mutations.REMOVE_ALL_ORDER_FILTER](state) {
    state.filters = [];
  },
  [Mutations.SET_ORDERS_SEARCH](state, search) {
    state.search = search;
  },
  [Mutations.REMOVE_ORDER_FILTER](state, [field, value]) {
    state.filters = state.filters.filter(
      ([f, v]) => `${f}:${v}` !== `${field}:${value}`
    );
  },
  [Mutations.SET_ORDER_SELECTED](state, selected) {
    state.selected = selected;
  },
  [Mutations.SET_ORDER_COUNT](state, count) {
    state.count = count;
  },
  [Mutations.SET_ORDER_FROM_MONGO](state, order) {
    state.orderFromMongo = order
      ? {
          ...state.orderFromMongo,
          ...order,
          dealer: order.dealer ? order.dealer : state.orderFromMongo?.dealer
        }
      : null;
  },
  [Mutations.SET_ALL_ORDERS_FROM_MONGO](state, orders) {
    state.allOrdersFromMongo = orders;
  },
  [Mutations.UPDATE_ORDER_FROM_LIST](state, order) {
    const orderIndex = state.allOrdersFromMongo.findIndex(
      (val) => val.orderId === order.orderId
    );
    if (orderIndex !== -1) {
      state.allOrdersFromMongo[orderIndex] = { ...order };
    }
  },
  [Mutations.SET_ORDER_LOADING_MESSAGE](state, message) {
    state.loadingMessage = message;
  },
  [Mutations.SET_UPDATE_ORDER](state, data) {
    state.orders = state.orders.map((order) => {
      if (order.id === data.id) {
        return {
          ...order,
          data: {
            ...order.data,
            ...data.changes
          }
        };
      }

      return order;
    });
  }
};
