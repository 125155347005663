const getTotalAmount = (cart) => {
  return cart.cart.products.reduce(
    (acc, item) => acc + Number(item.price * item.quantity),
    0
  );
};
const couponDetails = (state) =>
  state.allCoupons.find((coupon) => coupon.data.code === state.cart.couponCode)
    ?.data;
const discount = (state) => {
  switch (couponDetails(state).discount_type) {
    case "fixed_cart":
      return Number(couponDetails(state).amount);
    case "percent":
      return (
        (getTotalAmount(state) * Number(couponDetails(state).amount)) / 100
      );
    case "dealer":
      return 0;
    default:
      return 0;
  }
};
export default {
  getProductsAsOptions(state) {
    return state.products.map((prod) => ({
      value: prod.id,
      label: `${prod.name}${prod.sku ? ` [${prod.sku}]` : ""}`
    }));
  },
  posLoading(state) {
    return state.loading;
  },
  getProducts(state) {
    return state.products;
  },
  getCart(state) {
    return state.cart;
  },
  getCartTotal(state) {
    return getTotalAmount(state);
  },
  getShipping(state) {
    return getTotalAmount(state) <= 850 ? 9.9 : 0;
  },
  getDiscount(state) {
    return state.cart.couponCode ? discount(state) : 0;
  },
  getOrderTotal(state) {
    return (
      getTotalAmount(state) -
      (state.cart.couponCode !== "" ? discount(state) : 0) +
      (getTotalAmount(state) <= 850 ? 9.9 : 0)
    );
  },
  getPosErrors(state) {
    return state.errors;
  },
  getAllCoupons(state) {
    return state.allCoupons;
  },
  getCouponDetails(state) {
    return couponDetails(state);
  }
};
