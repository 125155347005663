import { doc, getDoc, deleteDoc } from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
const firestoreCollection = getFirestoreCollectionConfig();

export const deleteRegistrationRequest = async (registrationId) => {
  try {
    const docRef = doc(
      Firebase.db,
      firestoreCollection.account_register,
      registrationId
    );
    const snapshot = await getDoc(docRef);
    const dealerName = `${snapshot.data().name}`;
    const email = `${snapshot.data().email}`;
    await deleteDoc(docRef);
    return { dealerName: dealerName, success: true, email: email };
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};
