import { createStore } from "vuex";
import auth from "./auth_module";
import dealers from "./dealers_module";
import reports from "./reports_module";
import orders from "./orders_module";
import charts from "./charts_module";
import commercial from "./commercial_module";
import logs from "./logs_module";
import ably from "./ably_module";
import payment from "./payment_module";
import pos from "./pos_module";
import revenue from "./revenue_module";
import visitorlog from "./visitorlog_module";
import ServiceRequest from "./servicerequest_module";
import register from "./register_module";
import notification from "./notification_module";
export default createStore({
  modules: {
    auth,
    dealers,
    reports,
    orders,
    charts,
    commercial,
    logs,
    ably,
    payment,
    pos,
    revenue,
    visitorlog,
    ServiceRequest,
    register,
    notification
  }
});
