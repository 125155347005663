import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: `${process.env.VUE_APP_FIREBASE_API_KEY}`,
  authDomain: "sales-audit.firebaseapp.com",
  projectId: "sales-audit",
  storageBucket: "sales-audit.appspot.com",
  messagingSenderId: "613766804928",
  appId: "1:613766804928:web:85817ca3cb8962ae374425",
  measurementId: "G-2N8N3N1C0Y"
};
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager()
  })
});
const auth = getAuth(app);
const storage = getStorage(app);

const uploadFile = async (fileRef, file) => {
  const snapshot = await uploadBytes(fileRef, file);
  return getDownloadURL(snapshot.ref);
};

const getToken = async () => {
  const user = auth.currentUser;
  return user ? await user.getIdToken() : "";
};

export { db, ref, auth, storage, uploadFile, getToken };
