export default {
  getEbayAmazonSales(state) {
    return state.sales;
  },
  ebayAmazonFilter(state) {
    return state.filters;
  },
  ebayAmazonLoading(state) {
    return state.loading;
  }
};
