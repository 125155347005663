import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import { getAuth } from "firebase/auth";
import { signOutVisitorWithAuth, deleteVisitorLog } from "@/store/helpers";
import {
  getAllBranches,
  getAllVisitors,
  createVisitor,
  signOutVisitor,
  getAllVisitorsForToday
} from "@/common/salesAppApi";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import MODULE from "@/store/constants/module.constants";
import { serverTimestamp } from "firebase/firestore";

export default {
  async [Actions.GET_ALL_BRANCHES]({ commit }) {
    try {
      const { data } = await getAllBranches();
      const branches = data.branches.sort((a, b) =>
        a.branch.localeCompare(b.branch)
      );
      commit(Mutations.SET_ALL_BRANCHES, branches);
    } catch (error) {
      commit(Mutations.SET_VISITOR_ERROR, error);
    }
  },
  [Actions.SET_PERSONNEL]({ commit }, payload) {
    commit(Mutations.UPDATE_PERSONNEL, payload);
  },
  async [Actions.GET_ALL_VISITORS]({ commit }, { page = 1, filter }) {
    try {
      commit(Mutations.SET_VISITOR_LOADING, true);
      const { data } = await getAllVisitors({ page, filter });
      commit(Mutations.SET_ALL_VISITORS, data);
      commit(Mutations.SET_VISITOR_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_VISITOR_ERROR, error);
      commit(Mutations.SET_VISITOR_LOADING, false);
    }
  },
  async [Actions.GET_TODAYS_VISITORS]({ commit }) {
    try {
      commit(Mutations.SET_VISITOR_LOADING, true);
      const { data } = await getAllVisitorsForToday();
      commit(Mutations.SET_VISITOR_TODAY, data);
      commit(Mutations.SET_VISITOR_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_VISITOR_ERROR, error);
      commit(Mutations.SET_VISITOR_LOADING, false);
    }
  },
  async [Actions.CREATE_VISITOR]({ commit }, payload) {
    try {
      Object.keys(payload).forEach((key) => {
        if (typeof payload[key] === "string") {
          payload[key] = payload[key].toUpperCase();
        }
      });
      const { data } = await createVisitor(payload);
      const doc = data.doc;
      commit(Mutations.ADD_VISITOR, doc);
      return doc;
    } catch (error) {
      commit(Mutations.SET_VISITOR_ERROR, error);
    }
  },
  async [Actions.SIGN_OUT_VISITOR]({ commit, dispatch }, payload) {
    const currentUser = getAuth()?.currentUser;
    try {
      const { data } = currentUser
        ? await signOutVisitorWithAuth(payload)
        : await signOutVisitor(payload);
      const doc = data.doc;
      currentUser
        ? await dispatch(Actions.LOG_EVENT, {
            user: currentUser.email,
            action: LOG_ACTIONS.SIGN_OUT_VISITOR,
            module: MODULE.VISITOR,
            target: JSON.stringify({
              visitor_name: `${doc.firstName} ${doc.lastName}`
            }),
            datePerformed: serverTimestamp()
          })
        : null;
      commit(Mutations.UPDATE_VISITOR, doc);
      return currentUser ? doc : data.success;
    } catch (error) {
      commit(Mutations.SET_VISITOR_ERROR, error);
    }
  },
  async [Actions.DELETE_VISITOR_LOG]({ commit, dispatch }, payload) {
    const currentUser = getAuth()?.currentUser;
    try {
      const data = await deleteVisitorLog(payload);
      const { visitorName, success } = data;
      if (success) {
        commit(Mutations.UPDATE_ALL_VISITOR, payload);
        await dispatch(Actions.LOG_EVENT, {
          user: currentUser.email,
          action: LOG_ACTIONS.DELETE_VISITOR,
          module: MODULE.VISITOR,
          target: JSON.stringify({
            visitor_name: visitorName
          }),
          datePerformed: serverTimestamp()
        });
      }
    } catch (error) {
      commit(Mutations.SET_VISITOR_ERROR, error);
    }
  },
  [Actions.ADD_VISITOR_FILTER]({ commit }, payload) {
    commit(Mutations.PUSH_VISITOR_FILTER, payload);
  },
  [Actions.REMOVE_VISITOR_FILTER]({ commit }, payload) {
    commit(Mutations.DELETE_VISITOR_FILTER, payload);
  },
  [Actions.CLEAR_VISITOR_FILTERS]({ commit }) {
    commit(Mutations.RESET_VISITOR_FILTERS);
  },
  [Actions.SEARCH_VISITORS]({ commit }, payload) {
    commit(Mutations.SET_SEARCH_VISITORS, payload);
  },
  [Actions.SORT_VISITORS]({ commit }, payload) {
    commit(Mutations.SET_SORT_VISITORS, payload);
  },
  [Actions.SELECT_TO_DELETE_VISITOR_LOG]({ commit }, payload) {
    commit(Mutations.SET_VISITOR_TO_DELETE, payload);
  }
};
