import * as Constants from "@/store/constants";
import * as Helpers from "@/store/helpers";
export default {
  errors: null,
  dealers: [],
  completed: [],
  loading: true,
  selected: [],
  filters: [],
  order: null,
  search: "",
  sales: [],
  attributes: Constants.DEFAULT_ATTRIBUTES,
  amazon_ebay_filter: Helpers.getCurrentYear().toString()
};
