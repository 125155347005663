import * as Mutations from "@/store/mutations.type";
export default {
  [Mutations.SET_ALL_REPORTS](state, reports) {
    state.reports = reports;
  },
  [Mutations.SET_REPORT](state, report) {
    state.reports = state.reports.map((item) =>
      item.id === report.id ? { id: report.id, data: report.data } : item
    );
  },
  [Mutations.SET_REPORT_LOADING](state, loading) {
    state.loading = loading;
  }
};
