export default {
  errors: null,
  customers: [],
  loading: true,
  loadingMessage: "",
  filters: [],
  search: "",
  selected: [],
  order: null
};
