/**
 * 
 * @param {object} original the initial object
 * @param {object} modified the new object to be compared
 * @returns an array of objects that contains the fields that was changed
 */
function objectCompare(original, modified) {
  const changes = {};

  // Helper function to recursively compare properties
  function compareValues(path, oldValue, newValue) {
    if (typeof oldValue === 'object' && typeof newValue === 'object' && oldValue !== null && newValue !== null) {
      if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        compareArrays(path, oldValue, newValue);
      } else {
        compareObjects(oldValue, newValue, path);
      }
    } else if (oldValue !== newValue) {
      changes[path.join('.')] = {
        oldValue,
        newValue
      };
    }
  }

  function compareArrays(path, oldArray, newArray) {
    oldArray.forEach((oldValue, index) => {
      const newValue = newArray[index];
      const newPath = [...path, index];
      compareValues(newPath, oldValue, newValue);
    });

    // Check for added elements in the modified array
    newArray.slice(oldArray.length).forEach((newValue, index) => {
      const newPath = [...path, oldArray.length + index];
      changes[newPath.join('.')] = {
        oldValue: undefined,
        newValue
      };
    });
  }

  function compareObjects(obj1, obj2, path = []) {

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Checks whether keys in obj2 exists in obj1
    keys2.forEach(key => {
      if (!keys1.includes(key)) {
        changes[[...path, key].join('.')] = {
          oldValue: undefined,
          newValue: obj2[key]
        };
      }
    });

    // Checks whether keys in obj1 exists in obj2
    keys1.forEach(key => {
      const newPath = [...path, key];
      if (keys2.includes(key)) {
        compareValues(newPath, obj1[key], obj2[key]);
      } else {
        changes[newPath.join('.')] = {
          oldValue: obj1[key],
          newValue: undefined
        };
      }
    });
  }

  compareObjects(original, modified);

  return changes;
}


export {
  objectCompare
}