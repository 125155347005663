export const DEFAULT_ATTRIBUTES = [
  {
    field: "state",
    title: "State",
    options: [
      { label: "Queensland", value: "Queensland" },
      { label: "Western Australia", value: "Western Australia" },
      { label: "South Australia", value: "South Australia" },
      { label: "New South Wales", value: "New South Wales" },
      { label: "Northern Territory", value: "Northern Territory" },
      {
        label: "Australian Capital Territory",
        value: "Australian Capital Territory"
      },
      { label: "Victoria", value: "Victoria" },
      { label: "Tasmania", value: "Tasmania" },
      { label: "Hawke's Bay", value: "Hawke's Bay" },
      { label: "Waikato", value: "Waikato" },
      { label: "Gisborne", value: "Gisborne" },
      { label: "Auckland", value: "Auckland" },
      { label: "Wellington", value: "Wellington" },
      { label: "Taranaki", value: "Taranaki" },
      { label: "Canterbury", value: "Canterbury" },
      { label: "Metro Manila", value: "Metro Manila" },
      { label: "Kandal Province", value: "Kandal Province" },
      { label: "Chang Wat Phuket", value: "Chang Wat Phuket" },
      { label: "Delhi", value: "Delhi" },
      { label: "Chang Wat Samut Prakan", value: "Chang Wat Samut Prakan" },
      { label: "Taipei City", value: "Taipei City" },
      { label: "Hà Nội", value: "Hà Nội" },
      { label: "Hồ Chí Minh", value: "Hồ Chí Minh" },
      { label: "Dubai", value: "Dubai" },
      {
        label: "Daerah Khusus Ibukota Jakarta",
        value: "Daerah Khusus Ibukota Jakarta"
      },
      {
        label: "Yangon Region",
        value: "Yangon Region"
      },
      {
        label: "Selangor",
        value: "Selangor"
      }
    ]
  },
  {
    field: "rep",
    title: "Account Manager",
    options: [
      { label: "Alexander Ryan", value: "6643fd5fddbb005a55a42aef" },
      { label: "Anthony Duncan", value: "641007d9713d8cc7a5100f2a" },
      { label: "Ashleigh Ridgeway", value: "61ea2e5e74f79ed44df0e995" },
      { label: "Brendan Baeb", value: "62423b4cb7f80739c7cce7dd" },
      { label: "Christian Tyra", value: "5f17c2473861806c019a1533" },
      { label: "Daniel Ryley", value: "6119d3d6e8bb281153892eaa" },
      { label: "Janine Rowan", value: "665fd98c058c6cab3bd56f15" },
      { label: "Joel Hawken", value: "66beae9601f5d5ba8bf32d98" },
      { label: "John Arbidans", value: "6711e5024ebe5b7a37f0f94d" },
      { label: "Justin Singh", value: "5f14d60cbac09b0abe51102d" },
      { label: "Justin Hourigan", value: "65b87371339b0985e7e7b326" },
      { label: "Kenneth Tizon", value: "6711e72bbb19d9aded4836d3" },
      { label: "Lewton Thomas", value: "623a7632b75cbb388bca5598" },
      { label: "Nicholas Evans", value: "65b9d5f4d50fe9c4e51e9109" },
      { label: "Regan Grainger", value: "5f14d80cbac09b0abe511032" },
      { label: "Stephen Adam", value: "5f14d5c1bac09b0abe51102c" },
      { label: "Steve McKellar", value: "5f14d842bac09b0abe511033" },
      { label: "Tom Dewhirst", value: "634617c55afc3be3922d20ed" }
    ]
  },
  {
    field: "flag",
    title: "Flag",
    options: [
      { label: "Ecomm", value: "ecomm" },
      { label: "Leads", value: "leads" },
      { label: "Service", value: "service" },
      { label: "Builder", value: "builder" },
      { label: "Retailer", value: "retailer" },
      { label: "Mobile", value: "mobile" },
      { label: "Narellan", value: "narellan" },
      { label: "Barrier Reef", value: "barrierreef" }
    ]
  },
  {
    field: "type",
    title: "Type",
    options: [
      { label: "Elite", value: "elite" },
      { label: "Elite Builder", value: "elitebuilder" },
      { label: "Robotic Repairs", value: "roboticrepairs" },
      { label: "Mineral Swim", value: "mineralswim" },
      { label: "Water Treatment Repairs", value: "watertreatmentrepairs" },
      { label: "Ozone Swim", value: "ozoneswim" },
      { label: "Supreme", value: "supreme" },
      { label: "Franchisee", value: "franchisee" },
      { label: "Builder", value: "builder" },
      { label: "Standard", value: "standard" },
      { label: "Mobile", value: "mobile" },
      { label: "Commercial", value: "commercial" },
      { label: "Distributor", value: "distributor" },
      { label: "Trade", value: "trade" },
      { label: "Jims", value: "jims" },
      { label: "Service", value: "service" },
      { label: "Warehouse", value: "warehouse" },
      { label: "Retail", value: "retailer" },
      { label: "Retailer", value: "retailer" },
      { label: "Exporter", value: "exporter" }
    ]
  },
  {
    field: "status",
    title: "Status",
    options: [
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" }
    ]
  },
  {
    field: "active_sprout_completed",
    title: "Sprinklr",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false }
    ]
  }
];

export const DEALER_CHECKLIST_ELEMENTS = [
  {
    field: "dealer_elements",
    title: "DEALER CHECKIST ELEMENTS",
    options: [
      {
        label:
          "What competitive activity is happening instore? Promotions, POS.",
        value: "competitive_activity",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "competitive_activity_completed",
        taskDeadline: "competitive_activity_deadline",
        url: "competitive_activity_url",
        notes: "competitive_activity_notes",
        n_a: "competitive_activity_n_a"
      },
      {
        label: "Current active point of sales instore",
        value: "current_active_pos",
        category: "marketing",
        area: "promotional_activity_in_store",
        description: "",
        completed: "current_active_pos_completed",
        taskDeadline: "current_active_pos_deadline",
        url: "current_active_pos_url",
        notes: "current_active_pos_notes",
        completed_pos: {
          dolphin: "dolphin-completed_pos",
          tictac: "tictac-completed_pos",
          mineral: "mineral-completed_pos",
          ozone: "ozone-completed_pos"
        },
        n_a: "current_active_pos_n_a"
      },
      {
        label:
          "Dolphin Displays, Tic Tac Tank set up, clean and current models displayed.",
        value: "clean_up_to_date_pos",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "clean_up_to_date_pos_completed",
        taskDeadline: "clean_up_to_date_pos_deadline",
        url: "clean_up_to_date_pos_url",
        notes: "clean_up_to_date_pos_notes",
        n_a: "clean_up_to_date_pos_n_a"
      },
      {
        label:
          "Demo Cleaner in Tic Tac tank less than 12 months old, and a top class model",
        value: "top_line_displayed",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "top_line_displayed_completed",
        taskDeadline: "top_line_displayed_deadline",
        url: "top_line_displayed_url",
        notes: "top_line_displayed_notes",
        n_a: "top_line_displayed_n_a"
      },
      {
        label:
          "Are Maytronics  brochures, flyers etc on display and are they current?",
        value: "brochures_up_to_date",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "brochures_up_to_date_completed",
        taskDeadline: "brochures_up_to_date_deadline",
        url: "brochures_up_to_date_url",
        notes: "brochures_up_to_date_notes",
        n_a: "brochures_up_to_date_n_a"
      },
      {
        label:
          "Local Area Marketing (LAM) rebate - Co-Contribution Local Are Marketing Campaign has been completed this year.",
        value: "lam_opportunities",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "lam_opportunities_completed",
        taskDeadline: "lam_opportunities_deadline",
        url: "lam_opportunities_url",
        notes: "lam_opportunities_notes",
        n_a: "lam_opportunities_n_a"
      },
      {
        label:
          "Customised Digital Ring Fencing Promotion completed (Link to LAM campaign and rebate)",
        value: "customised_digital_ring_fencing",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "customised_digital_ring_fencing_completed",
        taskDeadline: "customised_digital_ring_fencing_deadline",
        url: "customised_digital_ring_fencing_url",
        notes: "customised_digital_ring_fencing_notes",
        n_a: "customised_digital_ring_fencing_n_a"
      },
      {
        label: "WT Displays set up, clean and current models.",
        value: "wt_displays_setup",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "wt_displays_setup_completed",
        taskDeadline: "wt_displays_setup_deadline",
        url: "wt_displays_setup_url",
        notes: "wt_displays_setup_notes",
        n_a: "wt_displays_setup_n_a"
      },
      {
        label: "Point of sales items - clean, up to date and operational.",
        value: "point_of_sales_items",
        area: "promotional_activity_in_store",
        category: "marketing",
        description: "",
        completed: "point_of_sales_items_completed",
        taskDeadline: "point_of_sales_items_deadline",
        url: "point_of_sales_items_url",
        notes: "point_of_sales_items_notes",
        n_a: "point_of_sales_items_n_a"
      },
      {
        label: "Is the Dealer signed up to Sprinklr?",
        value: "active_sprout",
        area: "promotional_activity_online",
        category: "marketing",
        description: "",
        completed: "active_sprout_completed",
        taskDeadline: "active_sprout_deadline",
        url: "active_sprout_url",
        notes: "active_sprout_notes",
        n_a: "active_sprout_n_a"
      },
      {
        label: "Does the Dealer have MT eComm embedded on their website?",
        value: "ecomm_embedded",
        area: "promotional_activity_online",
        category: "marketing",
        description: "",
        completed: "ecomm_embedded_completed",
        taskDeadline: "ecomm_embedded_deadline",
        url: "ecomm_embedded_url",
        notes: "ecomm_embedded_notes",
        n_a: "ecomm_embedded_n_a"
      },
      {
        label: "Is the Dealer mini-site active and up to date?",
        value: "mini_site_active",
        area: "promotional_activity_online",
        category: "marketing",
        description: "",
        completed: "mini_site_active_completed",
        taskDeadline: "mini_site_active_deadline",
        url: "mini_site_active_url",
        notes: "mini_site_active_notes",
        n_a: "mini_site_active_n_a"
      },
      {
        label: "Is the Dealer receiving Elite Bulletins?",
        value: "elite_bulletins",
        area: "admin_detail_check",
        category: "marketing",
        description: "",
        completed: "elite_bulletins_completed",
        taskDeadline: "elite_bulletins_deadline",
        url: "elite_bulletins_url",
        notes: "elite_bulletins_notes",
        n_a: "elite_bulletins_n_a"
      },
      {
        label: "Is the Dealer signed up to the Elite Dealer Facebook page?",
        value: "elite_facebook",
        area: "admin_detail_check",
        category: "marketing",
        description: "",
        completed: "elite_facebook_completed",
        taskDeadline: "elite_facebook_deadline",
        url: "elite_facebook_url",
        notes: "elite_facebook_notes",
        n_a: "elite_facebook_n_a"
      },
      {
        label:
          "Has the Dealer attended the MTAU Conference and received the Rebate? ",
        value: "mtau_conference",
        area: "admin_detail_check",
        category: "marketing",
        description: "",
        completed: "mtau_conference_completed",
        taskDeadline: "mtau_conference_deadline",
        url: "mtau_conference_url",
        notes: "mtau_conference_notes",
        n_a: "mtau_conference_n_a"
      },
      {
        label: "Current Yearly Sales Target",
        value: "current_yearly_sales_target",
        category: "sales",
        description: "",
        completed: "current_yearly_sales_targe_completed",
        taskDeadline: "current_yearly_sales_targe_deadline",
        url: "current_yearly_sales_targe_url",
        notes: "current_yearly_sales_targe_notes",
        n_a: "current_yearly_sales_target_n_a"
      },
      {
        label: "Dolphin",
        value: "sales_target_split_dolphin",
        area: "sales_target_split",
        category: "sales",
        description: "",
        completed: "sales_target_split_dolphin_completed",
        taskDeadline: "sales_target_split_dolphin_deadline",
        url: "sales_target_split_dolphin_url",
        notes: "sales_target_split_dolphin_notes",
        n_a: "sales_target_split_dolphin_n_a"
      },
      {
        label: "Mineral Swim",
        area: "sales_target_split",
        value: "sales_target_split_mineral_swim",
        category: "sales",
        description: "",
        completed: "sales_target_split_mineral_swim_completed",
        taskDeadline: "sales_target_split_mineral_swim_deadline",
        url: "sales_target_split_mineral_swim_url",
        notes: "sales_target_split_mineral_swim_notes",
        n_a: "sales_target_split_mineral_swim_n_a"
      },
      {
        label: "Ozone Swim",
        area: "sales_target_split",
        value: "sales_target_split_ozone_swim",
        category: "sales",
        description: "",
        completed: "sales_target_split_ozone_swim_completed",
        taskDeadline: "sales_target_split_ozone_swim_deadline",
        url: "sales_target_split_ozone_swim_url",
        notes: "sales_target_split_ozone_swim_notes",
        n_a: "sales_target_split_ozone_swim_n_a"
      },
      {
        label: "Eco Swim",
        area: "sales_target_split",
        value: "sales_target_split_eco_swim",
        category: "sales",
        description: "",
        completed: "sales_target_split_eco_swim_completed",
        taskDeadline: "sales_target_split_eco_swim_deadline",
        url: "sales_target_split_eco_swim_url",
        notes: "sales_target_split_eco_swim_notes",
        n_a: "sales_target_split_eco_swim_n_a"
      },
      {
        label: "Minerals - Mineral Swim",
        area: "sales_target_split",
        value: "sales_target_split_minerals_mineral_swim",
        category: "sales",
        description: "",
        completed: "sales_target_split_minerals_mineral_swim_completed",
        taskDeadline: "sales_target_split_minerals_mineral_swim_deadline",
        url: "sales_target_split_minerals_mineral_swim_url",
        notes: "sales_target_split_minerals_mineral_swim_notes",
        n_a: "sales_target_split_minerals_mineral_swim_n_a"
      },
      {
        label: "Minerals - Eco Swim",
        area: "sales_target_split",
        value: "sales_target_split_minerals_eco_swim",
        category: "sales",
        description: "",
        completed: "sales_target_split_minerals_eco_swim_completed",
        taskDeadline: "sales_target_split_minerals_eco_swim_deadline",
        url: "sales_target_split_minerals_eco_swim_url",
        notes: "sales_target_split_minerals_eco_swim_notes",
        n_a: "sales_target_split_minerals_eco_swim_n_a"
      },
      {
        label: "Eco Clear - Glass Media",
        area: "sales_target_split",
        value: "sales_target_split_eco_clear_glass_media",
        category: "sales",
        description: "",
        completed: "sales_target_split_eco_clear_glass_media_completed",
        taskDeadline: "sales_target_split_eco_clear_glass_media_deadline",
        url: "sales_target_split_eco_clear_glass_media_url",
        notes: "sales_target_split_eco_clear_glass_media_notes",
        n_a: "sales_target_split_eco_clear_glass_media_n_a"
      },
      {
        label: "Eco Clear - Other",
        area: "sales_target_split",
        value: "sales_target_split_eco_clear_other",
        category: "sales",
        description: "",
        completed: "sales_target_split_eco_clear_other_completed",
        taskDeadline: "sales_target_split_eco_clear_other_deadline",
        url: "sales_target_split_eco_clear_other_url",
        notes: "sales_target_split_eco_clear_other_notes",
        n_a: "sales_target_split_eco_clear_other_n_a"
      },
      {
        label: "Spare Parts",
        area: "sales_target_split",
        value: "sales_target_split_spare_parts",
        category: "sales",
        description: "",
        completed: "sales_target_split_spare_parts_completed",
        taskDeadline: "sales_target_split_spare_parts_deadline",
        url: "sales_target_split_spare_parts_url",
        notes: "sales_target_split_spare_parts_notes",
        n_a: "sales_target_split_spare_parts_n_a"
      },
      {
        label: "Dolphin",
        area: "top_level_activity",
        value: "top_level_activity_dolphin",
        category: "sales",
        description: "",
        completed: "top_level_activity_dolphin_completed",
        taskDeadline: "top_level_activity_dolphin_deadline",
        url: "top_level_activity_dolphin_url",
        notes: "top_level_activity_dolphin_notes",
        n_a: "top_level_activity_dolphin_n_a"
      },
      {
        label: "Mineral Swim",
        area: "top_level_activity",
        value: "top_level_activity_mineral_swim",
        category: "sales",
        description: "",
        completed: "top_level_activity_mineral_swim_completed",
        taskDeadline: "top_level_activity_mineral_swim_deadline",
        url: "top_level_activity_mineral_swim_url",
        notes: "top_level_activity_mineral_swim_notes",
        n_a: "top_level_activity_mineral_swim_n_a"
      },
      {
        label: "Ozone Swim",
        area: "top_level_activity",
        value: "top_level_activity_ozone_swim",
        category: "sales",
        description: "",
        completed: "top_level_activity_ozone_swim_completed",
        taskDeadline: "top_level_activity_ozone_swim_deadline",
        url: "top_level_activity_ozone_swim_url",
        notes: "top_level_activity_ozone_swim_notes",
        n_a: "top_level_activity_ozone_swim_n_a"
      },
      {
        label: "Eco Swim",
        area: "top_level_activity",
        value: "top_level_activity_eco_swim",
        category: "sales",
        description: "",
        completed: "top_level_activity_eco_swim_completed",
        taskDeadline: "top_level_activity_eco_swim_deadline",
        url: "top_level_activity_eco_swim_url",
        notes: "top_level_activity_eco_swim_notes",
        n_a: "top_level_activity_eco_swim_n_a"
      },
      {
        label: "Minerals - Mineral Swim",
        area: "top_level_activity",
        value: "top_level_activity_minerals_mineral_swim",
        category: "sales",
        description: "",
        completed: "top_level_activity_minerals_mineral_swim_completed",
        taskDeadline: "top_level_activity_minerals_mineral_swim_deadline",
        url: "top_level_activity_minerals_mineral_swim_url",
        notes: "top_level_activity_minerals_mineral_swim_notes",
        n_a: "top_level_activity_minerals_mineral_swim_n_a"
      },
      {
        label: "Minerals - Eco Swim",
        area: "top_level_activity",
        value: "top_level_activity_minerals_eco_swim",
        category: "sales",
        description: "",
        completed: "top_level_activity_minerals_eco_swim_completed",
        taskDeadline: "top_level_activity_minerals_eco_swim_deadline",
        url: "top_level_activity_minerals_eco_swim_url",
        notes: "top_level_activity_minerals_eco_swim_notes",
        n_a: "top_level_activity_minerals_eco_swim_n_a"
      },
      {
        label: "Eco Clear - Glass Media",
        area: "top_level_activity",
        value: "top_level_activity_eco_clear_glass_media",
        category: "sales",
        description: "",
        completed: "top_level_activity_eco_clear_glass_media_completed",
        taskDeadline: "top_level_activity_eco_clear_glass_media_deadline",
        url: "top_level_activity_eco_clear_glass_media_url",
        notes: "top_level_activity_eco_clear_glass_media_notes",
        n_a: "top_level_activity_eco_clear_glass_media_n_a"
      },
      {
        label: "Eco Clear - Other",
        area: "top_level_activity",
        value: "top_level_activity_eco_clear_other",
        category: "sales",
        description: "",
        completed: "top_level_activity_eco_clear_other_completed",
        taskDeadline: "top_level_activity_eco_clear_other_deadline",
        url: "top_level_activity_eco_clear_other_url",
        notes: "top_level_activity_eco_clear_other_notes",
        n_a: "top_level_activity_eco_clear_other_n_a"
      },
      {
        label: "Spare Parts",
        area: "top_level_activity",
        value: "top_level_activity_spare_parts",
        category: "sales",
        description: "",
        completed: "top_level_activity_spare_parts_completed",
        taskDeadline: "top_level_activity_spare_parts_deadline",
        url: "top_level_activity_spare_parts_url",
        notes: "top_level_activity_spare_parts_notes",
        n_a: "top_level_activity_spare_parts_n_a"
      },
      {
        label:
          "How to conduct a Dolphin Demonstration training completed with all Staff",
        value: "demonstration",
        area: "robotic_pool_cleaners",
        category: "training",
        description: "",
        completed: "demonstration_completed",
        taskDeadline: "demonstration_deadline",
        url: "demonstration_url",
        notes: "demonstration_notes",
        taskUpdate: "demonstration_update",
        n_a: "demonstration_n_a"
      },
      {
        label: "Dealer signed DIY Agreement and trained",
        area: "general",
        value: "dealer_signed_diy",
        category: "training",
        description: "",
        completed: "dealer_signed_diy_completed",
        taskDeadline: "dealer_signed_diy_deadline",
        url: "dealer_signed_diy_url",
        notes: "dealer_signed_diy_notes",
        taskUpdate: "dealer_signed_diy_update",
        n_a: "dealer_signed_diy_n_a"
      },
      {
        label: "Dolphin Product Training completed with all Staff",
        area: "robotic_pool_cleaners",
        value: "dolphin_product_training",
        category: "training",
        description: "",
        completed: "dolphin_product_training_completed",
        taskDeadline: "dolphin_product_training_deadline",
        url: "dolphin_product_training_url",
        notes: "dolphin_product_training_notes",
        taskUpdate: "dolphin_product_training_update",
        n_a: "dolphin_product_training_n_a"
      },
      {
        label: "Mineral Product Training completed with all Staff",
        value: "mineral_swim_pro",
        category: "training",
        area: "water_treatment",
        description: "",
        completed: "mineral_swim_pro_completed",
        taskDeadline: "mineral_swim_pro_deadline",
        url: "mineral_swim_pro_url",
        notes: "mineral_swim_pro_notes",
        taskUpdate: "mineral_swim_pro_update",
        n_a: "mineral_swim_pro_n_a"
      },
      {
        label: "Ozone Swim training completed with all Staff",
        area: "water_treatment",
        value: "ozone_swim",
        category: "training",
        description: "",
        completed: "ozone_swim_completed",
        taskDeadline: "ozone_swim_deadline",
        url: "ozone_swim_url",
        notes: "ozone_swim_notes",
        taskUpdate: "ozone_swim_update",
        n_a: "ozone_swim_n_a"
      },
      {
        label: "Eco Swim Chlorinator training completed with all Staff",
        area: "water_treatment",
        value: "eco_swim_chlorinator",
        category: "training",
        description: "",
        completed: "eco_swim_chlorinator_completed",
        taskDeadline: "eco_swim_chlorinator_deadline",
        url: "eco_swim_chlorinator_url",
        notes: "eco_swim_chlorinator_notes",
        taskUpdate: "eco_swim_chlorinator_update",
        n_a: "eco_swim_chlorinator_n_a"
      },
      {
        label: "Glass Media Training completed with all Staff",
        area: "water_treatment",
        value: "glass_media",
        category: "training",
        description: "",
        completed: "glass_media_completed",
        taskDeadline: "glass_media_deadline",
        url: "glass_media_url",
        notes: "glass_media_notes",
        taskUpdate: "glass_media_update",
        n_a: "glass_media_n_a"
      },
      {
        label: "Mineral Swim Pro training completed with all Staff",
        area: "water_treatment",
        value: "mineral_product_training",
        category: "training",
        description: "",
        completed: "mineral_product_training_completed",
        taskDeadline: "mineral_product_training_deadline",
        url: "mineral_product_training_url",
        notes: "mineral_product_training_notes",
        taskUpdate: "mineral_product_training_update",
        n_a: "mineral_product_training_n_a"
      },
      {
        label: "Best Practice Training completed with the Dealer",
        area: "instore_merchandising",
        value: "best_practice",
        category: "training",
        description: "",
        completed: "best_practice_completed",
        taskDeadline: "best_practice_deadline",
        url: "best_practice_url",
        notes: "best_practice_notes",
        taskUpdate: "best_practice_update",
        n_a: "best_practice_n_a"
      },
      {
        label: "Filtration Training",
        area: "water_treatment",
        value: "filtration_training",
        category: "training",
        description: "",
        completed: "filtration_training_completed",
        taskDeadline: "filtration_training_deadline",
        url: "filtration_training_url",
        notes: "filtration_training_notes",
        taskUpdate: "filtration_training_update",
        n_a: "filtration_training_n_a"
      },
      {
        label: "MPP Training completed with Dealer",
        area: "general",
        value: "mpp_ordering",
        category: "training",
        description: "",
        completed: "mpp_ordering_completed",
        taskDeadline: "mpp_ordering_deadline",
        url: "mpp_ordering_url",
        notes: "mpp_ordering_notes",
        taskUpdate: "mpp_ordering_update",
        n_a: "mpp_ordering_n_a"
      },
      {
        label:
          "Does the Dealer have any new starters since previous visit requiring training",
        area: "general",
        value: "dealer_starters_training",
        category: "training",
        description: "",
        completed: "dealer_starters_training_completed",
        taskDeadline: "dealer_starters_training_deadline",
        url: "dealer_starters_training_url",
        notes: "dealer_starters_training_notes",
        taskUpdate: "dealer_starters_training_update",
        n_a: "dealer_starters_training_n_a"
      },
      {
        label: "Heat Pump Training",
        area: "water_treatment",
        value: "heat_pump_training",
        category: "training",
        description: "",
        completed: "heat_pump_training_completed",
        taskDeadline: "heat_pump_training_deadline",
        url: "heat_pump_training_url",
        notes: "heat_pump_training_notes",
        taskUpdate: "heat_pump_training_update",
        n_a: "heat_pump_training_n_a"
      }
    ]
  }
];

export const STORAGE_PATH =
  "https://storage.googleapis.com/sales-audit.appspot.com/";
export const BROCHURES_URL =
  "https://s3.ap-southeast-2.amazonaws.com/maytronics.com/2022+Brochure+Catalogue_FA.pdf";
export const SPROUT_ONBOARDING =
  "https://s3.ap-southeast-2.amazonaws.com/maytronics.com/Social+Media+Guide+-+Elite+Dealer.pdf";
export const LATEST_PROMO_URL =
  "https://s3.ap-southeast-2.amazonaws.com/maytronics.com/Promo+Overview_2023.pdf";
