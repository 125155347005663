import * as Helpers from "@/store/helpers";
export default {
  allCustomers(state) {
    return state.customers;
  },
  customerLoading(state) {
    return state.loading;
  },
  customerSelected(state) {
    return state.selected;
  },
  filteredCustomerSearch(state) {
    let list = state.customers;
    list = Helpers.customerSearchFilterHelper(state.search, list);
    list = Helpers.customerSortHelper(state,list)
    return list;
  }
};
