export default {
  selectedDealer(state) {
    return state.dealer;
  },
  serviceLoading(state) {
    return state.loading;
  },
  getServiceRequestsCount(state) {
    return state.count;
  },
  serviceRequestsPickUp(state) {
    return state.serviceRequestsPickUp;
  },
  serviceRequestsDropOff(state) {
    return state.serviceRequestsDropOff;
  },
  getServiceRequestsCountDropOff(state) {
    return state.dropOffCount;
  }
};
