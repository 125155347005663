import Spaces from "@ably/spaces";
import { Realtime } from "ably";

const client = new Realtime.Promise({
  key: `${process.env.VUE_APP_ABLY_API}`,
  clientId: "salesapp"
});
const spaces = new Spaces(client);

export { spaces, client };
