export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const LOGO = {
  amazon:
    "https://images.ctfassets.net/ftcnv5up9dxv/1H0lzcRalYlLGr7u6DvKVk/cb975d1113ca03748cba6f290de7892c/emojipng.com-1902896.png",
  ebay: "https://images.ctfassets.net/ftcnv5up9dxv/6tglSXck4U77ahkSzzo1Yw/8451f0a93290c769cc1a51fe57464a83/ebay_PNG22.png",
  kogan:
    "https://images.ctfassets.net/ftcnv5up9dxv/66goQWmbtWIToSoSvw8eBR/cfb5c882bbfe337a5bb4669efcecd244/kogan-app-icon-preview.webp",
  catch:
    "https://images.ctfassets.net/ftcnv5up9dxv/3Frbs6MJ5i4xwuq63uI8sK/1eb6f3286b10a0155a8c65ceaf6d1b57/download.png"
};

export const INITIAL_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
