import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import { addDoc, collection, getDoc } from "firebase/firestore";
import { db } from "../../common/firebase";
import { getLogs } from "@/common/salesAppApi";
import { loadAvatar } from "@/utils/avatarLoader";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
import { publishAnnouncementAbly } from "@/utils/ably";

const firestoreCollection = getFirestoreCollectionConfig();

export default {
  async [Actions.LOG_EVENT](_, payload) {
    try {
      const docRef = await addDoc(
        collection(db, firestoreCollection.user_logs),
        payload
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();
        publishAnnouncementAbly([
          { ...docData, id: docRef.id, type: "activities" }
        ]);
        return docData;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  },
  async [Actions.GET_LOGS](
    { commit, rootState, dispatch },
    { page = 1, filter }
  ) {
    commit(Mutations.SET_LOG_LOADING, true);
    const { data } = await getLogs({ page, filter });
    let userState = rootState.auth.users;
    if (userState.length === 0) {
      await dispatch(Actions.GET_ALL_USERS);
      userState = rootState.auth.users;
    }
    data.logs = loadAvatar(userState, data.logs, ["email"], ["user"]);

    data.logs = data.logs.map((log) => {
      let parsedChanges = log.changes;
      let parsedTarget = log.target;

      if (log.changes) {
        try {
          parsedChanges = JSON.parse(log.changes);
        } catch (error) {
          commit(Mutations.SET_LOG_ERROR, error);
        }
      }

      if (log.target) {
        try {
          parsedTarget = JSON.parse(log.target);
        } catch (error) {
          commit(Mutations.SET_LOG_ERROR, error);
        }
      }
      return {
        ...log,
        ...(log.changes && { changes: parsedChanges }),
        ...(log.target && { target: parsedTarget })
      };
    });
    commit(Mutations.SET_LOGS, data);
    commit(Mutations.SET_LOG_LOADING, false);
  }
};
