import * as Helpers from "@/store/helpers";

export default {
  allOrders(state) {
    return state.orders;
  },
  allSucceededOrders(state) {
    const succeededOrders = state.orders.filter(
      (order) =>
        order.data.status !== "cancelled" && order.data.status !== "refunded"
    );
    return succeededOrders;
  },
  getAllOrderCount(state) {
    return state.count;
  },
  orderLoading(state) {
    return state.loading;
  },
  orderFilters(state) {
    return state.filters;
  },
  filteredOrderSearch(state) {
    let list = state.orders;
    list = Helpers.orderSearchFilterHelper(state.search, list);
    list = Helpers.orderAttributeFilterHelper(state, list);
    return list;
  },
  getSearch(state) {
    return state.search;
  },
  orderSelected(state) {
    return state.selected;
  },
  orderSelectedFromMongo(state) {
    return state.orderFromMongo;
  },
  allOrdersSelectedFromMongo(state) {
    return state.allOrdersFromMongo;
  },
  orderError(state) {
    return state.errors;
  },
  orderLoadingMessage(state) {
    return state.loadingMessage;
  }
};
