import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import * as Firebase from "@/common/firebase.js";
import * as Helpers from "@/store/helpers";
import { doc, setDoc } from "firebase/firestore";
export default {
  [Actions.GET_ALL_REPORTS]({ commit }) {
    return Helpers.getAllReports("reports")
      .then((reports) => {
        commit(Mutations.SET_ALL_REPORTS, [...reports]);
        commit(Mutations.SET_REPORT_LOADING, false);
      })
      .catch((error) => {
        commit(Mutations.SET_REPORT_LOADING, false);
        commit(Mutations.SET_REPORT_ERROR, error);
      });
  },
  [Actions.CREATE_REPORT]: async ({ commit }, { id, data }) => {
    const ref = doc(Firebase.db, "reports", `${id}`);
    await setDoc(ref, data, { merge: true })
      .then(() => {
        commit(Mutations.SET_REPORT, {
          data: data[id],
          id
        });
      })
      .catch((error) => {
        commit(Mutations.SET_REPORT_ERROR, error.message);
      });
  }
};
