import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_SELECTED_DEALER](state, dealer) {
    state.dealer = dealer;
  },
  [Mutations.SET_SERVICE_LOADING](state, loading) {
    state.loading = loading;
  },
  [Mutations.SET_SERVICEREQUEST_ERROR](state, error) {
    state.error = error;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_PICKUP](state, data) {
    state.serviceRequestsPickUp = data;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_DROPOFF](state, data) {
    state.serviceRequestsDropOff = data;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_COUNT](state, count) {
    state.count = count;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_COUNT_DROPOFF](state, count) {
    state.dropOffCount = count;
  }
};
