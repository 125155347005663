import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.ENTER_ROOM](state, payload) {
    if (!state.rooms[payload.room]) {
      state.rooms[payload.room] = [];
    }
    const isUserExistsInRoom = state.rooms[payload.room].find(user => user.email === payload.user.email);
    if (!isUserExistsInRoom) {
      state.rooms[payload.room].push(payload.user);
    }
  },
  [Mutations.LEAVE_ROOM](state, payload) {
    if (state.rooms[payload.room]) {
      state.rooms[payload.room] = state.rooms[payload.room]
        .filter(roomUser => payload.user.email !== roomUser.email);
    }
  },
  [Mutations.LOAD_ALL_MEMBERS](state, payload) {
    state.rooms[payload.room] = payload.users;
  }
}