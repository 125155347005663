import * as Firebase from "@/common/firebase.js";
import { collection, getDocs, query } from "firebase/firestore";

export const getAllCoupons = async (dbCollection) => {
  const coupons = await getDocs(query(collection(Firebase.db, dbCollection)));
  return coupons.empty
    ? parseCouponsDoc(getDocs(collection(Firebase.db, `${dbCollection}`)))
    : parseCouponsDoc(coupons);
};

export const parseCouponsDoc = (snapshot) => {
  const coupons = [];
  snapshot.forEach((doc) => {
    coupons.push({
      id: doc.id,
      data: doc.data()
    });
  });
  return coupons;
};
