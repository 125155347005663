import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import woocommerceClient from "@/common/woocommerce";
import { posEndPoint } from "@/common/mongo";
import * as Helpers from "@/store/helpers";
import { getAuth } from "firebase/auth";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import MODULE from "@/store/constants/module.constants";
import { serverTimestamp } from "firebase/firestore";

export default {
  async [Actions.GET_PRODUCTS]({ commit }) {
    commit(Mutations.SET_PRODUCT_LOADING, true);
    const { data } = await woocommerceClient.get("products", {
      status: "publish",
      per_page: 100
    });
    const productVariations = await Promise.all(
      data
        .filter((product) => product.variations.length > 0)
        .map(async (product) => {
          const variations = await Promise.all(
            product.variations.map(async (variation) => {
              const productVariation = await woocommerceClient.get(
                `products/${product.id}/variations/${variation}`
              );
              const correctedVariation = {
                ...productVariation.data,
                name: `${product.name} ${productVariation.data.name}`
              };
              return correctedVariation;
            })
          );
          return variations;
        })
    );
    const flattenedProductVariations = productVariations.flat();
    const singleProducts = data.filter(
      (product) => product.variations.length === 0
    );
    const allProducts = [...singleProducts, ...flattenedProductVariations];
    commit(Mutations.SET_PRODUCT_LOADING, false);
    commit(Mutations.SET_PRODUCTS, allProducts);
  },
  [Actions.RESET_CART]({ commit }) {
    commit(Mutations.RESET_CART);
  },
  [Actions.UPDATE_CART_CUSTOMER]({ commit }, payload) {
    commit(Mutations.SET_CART_CUSTOMER, payload);
  },
  [Actions.UPDATE_CART_ITEMS]({ commit }, payload) {
    commit(Mutations.SET_CART_ITEMS, payload);
  },
  async [Actions.POST_ORDER]({ commit, dispatch }, { payload, couponDetails }) {
    const currentUser = getAuth()?.currentUser;
    try {
      const { data } = await woocommerceClient.post("orders", payload);
      let updatedData = data;
      if (couponDetails) {
        if (couponDetails.discount_type !== "dealer") {
          updatedData = { ...data, assignedDealer: couponDetails.dealer_id };
        }
      }
      const response = await posEndPoint(updatedData);
      await dispatch(Actions.LOG_EVENT, {
        user: currentUser.email,
        action: LOG_ACTIONS.POS_POST_ORDER,
        module: MODULE.POS,
        target: JSON.stringify(response.data),
        datePerformed: serverTimestamp()
      });
      return response;
    } catch (error) {
      commit(Mutations.SET_POS_ERROR, error.response.data.message);
    }
  },
  async [Actions.GET_ALL_COUPONS]({ commit }) {
    try {
      const coupons = await Helpers.getAllCoupons("coupons");
      commit(Mutations.SET_ALL_COUPONS, coupons);
    } catch (error) {
      commit(Mutations.SET_POS_ERROR, error.response.data.message);
    }
  },
  [Actions.UPDATE_COUPON]({ commit }, payload) {
    commit(Mutations.SET_COUPON, payload);
  }
};
