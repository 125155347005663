import { collection, getDocs } from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";

export const getAllReports = async (dbCollection) => {
  const report = await getDocs(collection(Firebase.db, `${dbCollection}`));
  return report.empty
    ? parseReportDoc(getDocs(collection(Firebase.db, `${dbCollection}`)))
    : parseReportDoc(report);
};

export const parseReportDoc = (snapshot) => {
  const reports = [];
  snapshot.forEach((doc) => {
    reports.push({
      id: doc.id,
      data: doc.data()[doc.id]
    });
  });
  return reports;
};

export const initiateReportData = (data) => {
  const date = new Date();
  let year = date.getFullYear();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let currentMonth = month[date.getMonth()];
  if (data && data.custId) {
    return {
      [data.custId]: {
        [year]: {
          [currentMonth]: {
            market_current_promo: data.market_current_promo_completed || false,
            current_active_pos: data.current_active_pos_completed || false,
            clean_up_to_date_pos: data.clean_up_to_date_pos_completed || false,
            top_line_displayed: data.top_line_displayed_completed || false,
            brochures_up_to_date: data.brochures_up_to_date_completed || false,
            competitive_activity: data.competitive_activity_completed || false,
            lam_opportunities: data.lam_opportunities_completed || false,
            active_sprout: data.active_sprout_completed || false,
            ecomm_embedded: data.ecomm_embedded_completed || false,
            mini_site_active: data.mini_site_active_completed || false,
            elite_bulletins: data.elite_bulletins_completed || false,
            elite_facebook: data.elite_facebook_completed || false,
            market_current_promo_n_a: data.market_current_promo_n_a || false,
            current_active_pos_n_a: data.current_active_pos_n_a || false,
            clean_up_to_date_pos_n_a: data.clean_up_to_date_pos_n_a || false,
            top_line_displayed_n_a: data.top_line_displayed_n_a || false,
            brochures_up_to_date_n_a: data.brochures_up_to_date_n_a || false,
            competitive_activity_n_a: data.competitive_activity_n_a || false,
            lam_opportunities_n_a: data.lam_opportunities_n_a || false,
            active_sprout_n_a: data.active_sprout_n_a || false,
            ecomm_embedded_n_a: data.ecomm_embedded_n_a || false,
            mini_site_active_n_a: data.mini_site_active_n_a || false,
            elite_bulletins_n_a: data.elite_bulletins_n_a || false,
            elite_facebook_n_a: data.elite_facebook_n_a || false,
            market_current_promo_url: data.market_current_promo_url,
            current_active_pos_url: data.current_active_pos_url,
            clean_up_to_date_pos_url: data.clean_up_to_date_pos_url,
            top_line_displayed_url: data.top_line_displayed_url,
            brochures_up_to_date_url: data.brochures_up_to_date_url,
            competitive_activity_url: data.competitive_activity_url,
            lam_opportunities_url: data.lam_opportunities_url,
            active_sprout_url: data.active_sprout_url,
            ecomm_embedded_url: data.ecomm_embedded_url,
            mini_site_active_url: data.mini_site_active_url,
            elite_bulletins_url: data.elite_bulletins_url,
            elite_facebook_url: data.elite_facebook_url,
            market_current_promo_deadline:
              data.market_current_promo_deadline || "",
            current_active_pos_deadline: data.current_active_pos_deadline || "",
            clean_up_to_date_pos_deadline:
              data.clean_up_to_date_pos_deadline || "",
            top_line_displayed_deadline: data.top_line_displayed_deadline || "",
            brochures_up_to_date_deadline:
              data.brochures_up_to_date_deadline || "",
            competitive_activity_deadline:
              data.competitive_activity_deadline || "",
            lam_opportunities_deadline: data.lam_opportunities_deadline || "",
            active_sprout_deadline: data.active_sprout_deadline || "",
            ecomm_embedded_deadline: data.ecomm_embedded_deadline || "",
            mini_site_active_deadline: data.mini_site_active_deadline || "",
            elite_bulletins_deadline: data.elite_bulletins_deadline || "",
            elite_facebook_deadline: data.elite_facebook_deadline || "",
            market_current_promo_notes: data.market_current_promo_notes || "",
            current_active_pos_notes: data.current_active_pos_notes || "",
            clean_up_to_date_pos_notes: data.clean_up_to_date_pos_notes || "",
            top_line_displayed_notes: data.top_line_displayed_notes || "",
            brochures_up_to_date_notes: data.brochures_up_to_date_notes || "",
            competitive_activity_notes: data.competitive_activity_notes || "",
            lam_opportunities_notes: data.lam_opportunities_notes || "",
            active_sprout_notes: data.active_sprout_notes || "",
            ecomm_embedded_notes: data.ecomm_embedded_notes || "",
            mini_site_active_notes: data.mini_site_active_notes || "",
            elite_bulletins_notes: data.elite_bulletins_notes || "",
            elite_facebook_notes: data.elite_facebook_notes || "",
            demonstration_completed: data.demonstration_completed || false,
            top_down_sales_strategy_completed:
              data.top_down_sales_strategy_completed || false,
            dolphin_product_training_completed:
              data.dolphin_product_training_completed || false,
            mineral_swim_pro_completed:
              data.mineral_swim_pro_completed || false,
            ozone_swim_completed: data.ozone_swim_completed || false,
            eco_swim_chlorinator_completed:
              data.eco_swim_chlorinator_completed || false,
            glass_media_completed: data.glass_media_completed || false,
            mineral_product_training_completed:
              data.mineral_product_training_completed || false,
            best_practice_completed: data.best_practice_completed || false,
            maytronics_exceptional_experience_completed:
              data.maytronics_exceptional_experience_completed || false,
            mpp_ordering_completed: data.mpp_ordering_completed || false,
            dealer_starters_training_completed:
              data.dealer_starters_training_completed || false,
            demonstration_n_a: data.demonstration_n_a || false,
            top_down_sales_strategy_n_a:
              data.top_down_sales_strategy_n_a || false,
            dolphin_product_training_n_a:
              data.dolphin_product_training_n_a || false,
            mineral_swim_pro_n_a: data.mineral_swim_pro_n_a || false,
            ozone_swim_n_a: data.ozone_swim_n_a || false,
            eco_swim_chlorinator_n_a: data.eco_swim_chlorinator_n_a || false,
            glass_media_n_a: data.glass_media_n_a || false,
            mineral_product_training_n_a:
              data.mineral_product_training_n_a || false,
            best_practice_n_a: data.best_practice_n_a || false,
            maytronics_exceptional_experience_n_a:
              data.maytronics_exceptional_experience_n_a || false,
            mpp_ordering_n_a: data.mpp_ordering_n_a || false,
            dealer_starters_training_n_a:
              data.dealer_starters_training_n_a || false,
            demonstration_deadline: data.demonstration_deadline || "",
            top_down_sales_strategy_deadline:
              data.top_down_sales_strategy_deadline || "",
            dolphin_product_training_deadline:
              data.dolphin_product_training_deadline || "",
            mineral_swim_pro_deadline: data.mineral_swim_pro_deadline || "",
            ozone_swim_deadline: data.ozone_swim_deadline || "",
            eco_swim_chlorinator_deadline:
              data.eco_swim_chlorinator_deadline || "",
            glass_media_deadline: data.glass_media_deadline || "",
            mineral_product_training_deadline:
              data.mineral_product_training_deadline || "",
            best_practice_deadline: data.best_practice_deadline || "",
            maytronics_exceptional_experience_deadline:
              data.maytronics_exceptional_experience_deadline || "",
            mpp_ordering_deadline: data.mpp_ordering_deadline || "",
            dealer_starters_training_deadline:
              data.dealer_starters_training_deadline || "",
            demonstration_update: data.demonstration_update || "",
            top_down_sales_strategy_update:
              data.top_down_sales_strategy_update || "",
            dolphin_product_training_update:
              data.dolphin_product_training_update || "",
            mineral_swim_pro_update: data.mineral_swim_pro_update || "",
            ozone_swim_update: data.ozone_swim_update || "",
            eco_swim_chlorinator_update: data.eco_swim_chlorinator_update || "",
            glass_media_update: data.glass_media_update || "",
            mineral_product_training_update:
              data.mineral_product_training_update || "",
            best_practice_update: data.best_practice_update || "",
            maytronics_exceptional_experience_update:
              data.maytronics_exceptional_experience_update || "",
            mpp_ordering_update: data.mpp_ordering_update || "",
            dealer_starters_training_update:
              data.dealer_starters_training_update || "",
            demonstration_notes: data.demonstration_notes || "",
            top_down_sales_strategy_notes:
              data.top_down_sales_strategy_notes || "",
            dolphin_product_training_notes:
              data.dolphin_product_training_notes || "",
            mineral_swim_pro_notes: data.mineral_swim_pro_notes || "",
            ozone_swim_notes: data.ozone_swim_notes || "",
            eco_swim_chlorinator_notes: data.eco_swim_chlorinator_notes || "",
            glass_media_notes: data.glass_media_notes || "",
            mineral_product_training_notes:
              data.mineral_product_training_notes || "",
            best_practice_notes: data.best_practice_notes || "",
            maytronics_exceptional_experience_notes:
              data.maytronics_exceptional_experience_notes || "",
            mpp_ordering_notes: data.mpp_ordering_notes || "",
            dealer_starters_training_notes:
              data.dealer_starters_training_notes || "",
            filtration_training_n_a: data.filtration_training_n_a || false,
            heat_pump_training_n_a: data.heat_pump_training_n_a || false,
            dealer_signed_diy_n_a: data.dealer_signed_diy_n_a || false,
            filtration_training_completed:
              data.filtration_training_completed || false,
            filtration_training_deadline:
              data.filtration_training_deadline || "",
            filtration_training_url: data.filtration_training_url || "",
            filtration_training_notes: data.filtration_training_notes || "",
            filtration_training_update: data.filtration_training_update || "",
            heat_pump_training_completed:
              data.heat_pump_training_completed || false,
            heat_pump_training_deadline: data.heat_pump_training_deadline || "",
            heat_pump_training_url: data.heat_pump_training_url || "",
            heat_pump_training_notes: data.heat_pump_training_notes || "",
            heat_pump_training_update: data.heat_pump_training_update || "",
            dealer_signed_diy_completed:
              data.dealer_signed_diy_completed || false,
            dealer_signed_diy_deadline: data.dealer_signed_diy_deadline || "",
            dealer_signed_diy_url: data.dealer_signed_diy_url || "",
            dealer_signed_diy_notes: data.dealer_signed_diy_notes || "",
            dealer_signed_diy_update: data.dealer_signed_diy_update || "",
            customised_digital_ring_fencing_completed:
              data.customised_digital_ring_fencing_completed || false,
            customised_digital_ring_fencing_deadline:
              data.customised_digital_ring_fencing_deadline || "",
            customised_digital_ring_fencing_url:
              data.customised_digital_ring_fencing_url || "",
            customised_digital_ring_fencing_notes:
              data.customised_digital_ring_fencing_notes || "",
            customised_digital_ring_fencing_n_a:
              data.customised_digital_ring_fencing_n_a || false,
            wt_displays_setup_completed:
              data.wt_displays_setup_completed || false,
            wt_displays_setup_deadline: data.wt_displays_setup_deadline || "",
            wt_displays_setup_url: data.wt_displays_setup_url || "",
            wt_displays_setup_notes: data.wt_displays_setup_notes || "",
            wt_displays_setup_n_a: data.wt_displays_setup_n_a || false,
            point_of_sales_items_completed:
              data.point_of_sales_items_completed || false,
            point_of_sales_items_deadline:
              data.point_of_sales_items_deadline || "",
            point_of_sales_items_url: data.point_of_sales_items_url || "",
            point_of_sales_items_notes: data.point_of_sales_items_notes || "",
            point_of_sales_items_n_a: data.point_of_sales_items_n_a || false,
            mtau_conference_completed: data.mtau_conference_completed || false,
            mtau_conference_deadline: data.mtau_conference_deadline || "",
            mtau_conference_url: data.mtau_conference_url || "",
            mtau_conference_notes: data.mtau_conference_notes || "",
            mtau_conference_n_a: data.mtau_conference_n_a || false,
            current_yearly_sales_target_url:
              data.current_yearly_sales_targe_url || "",
            current_yearly_sales_target_notes:
              data.current_yearly_sales_targe_notes || "",
            current_yearly_sales_target_n_a:
              data.current_yearly_sales_target_n_a || false,
            lastModifiedBy: Firebase.auth.currentUser.email,
            timestamp: new Date(),
            completed: data.completed || []
          }
        }
      }
    };
  }
};
