import { collection, getDocs } from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
const firestoreCollection = getFirestoreCollectionConfig();

export const getAllRevenue = async () => {
  const revenue = await getDocs(
    collection(Firebase.db, firestoreCollection.revenue)
  );
  return revenue.empty
    ? parseRevenueDoc(
        getDocs(collection(Firebase.db, firestoreCollection.revenue))
      )
    : parseRevenueDoc(revenue);
};
export const getAllSparePartsRevenue = async () => {
  const revenue = await getDocs(
    collection(Firebase.db, firestoreCollection.sparePartsRevenue)
  );
  return revenue.empty
    ? parseRevenueDoc(
        getDocs(collection(Firebase.db, firestoreCollection.sparePartsRevenue))
      )
    : parseRevenueDoc(revenue);
};
export const parseRevenueDoc = (snapshot) => {
  const revenue = [];
  snapshot.forEach((doc) => {
    revenue.push({
      ...doc.data()
    });
  });
  return revenue;
};
