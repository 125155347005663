import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import * as Firebase from "@/common/firebase.js";
import * as Helpers from "@/store/helpers";
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  serverTimestamp
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import LOG_ACTIONS from "../constants/log_actions.constants";
import MODULE from "../constants/module.constants";
import { objectCompare } from "../../utils/objectComparator";
import { loadAvatar } from "@/utils/avatarLoader";
import { sendCustomerConditionReport } from "@/common/salesAppApi";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";

const firestoreCollection = getFirestoreCollectionConfig();

export default {
  [Actions.CREATE_CUSTOMER]: async ({ commit, dispatch, rootState }, data) => {
    const { id } = await addDoc(
      collection(Firebase.db, firestoreCollection.commercial),
      data
    );
    const user = getAuth().currentUser;
    await dispatch(Actions.LOG_EVENT, {
      user: user.email,
      action: LOG_ACTIONS.CREATE_CUSTOMER,
      module: MODULE.COMMERCIAL,
      target: JSON.stringify({ id, name: data.pool_name }),
      datePerformed: serverTimestamp()
    });
    let userState = rootState.auth.users;
    if (userState.length === 0) {
      await dispatch(Actions.GET_ALL_USERS);
      userState = rootState.auth.users;
    }
    const avatar = loadAvatar(userState, [data], ["email"], ["created_by"])[0]
      .avatar;
    commit(Mutations.SET_CUSTOMER, { id: id, data: data, avatar });
  },
  async [Actions.GET_ALL_CUSTOMERS]({ commit, rootState, dispatch }) {
    try {
      const customers = await Helpers.getAllCustomers(
        firestoreCollection.commercial
      );
      let userState = rootState.auth.users;
      if (userState.length === 0) {
        await dispatch(Actions.GET_ALL_USERS);
        userState = rootState.auth.users;
      }
      const customersWithAvatar = loadAvatar(
        userState,
        customers,
        ["email"],
        ["data", "created_by"]
      );
      commit(Mutations.SET_ALL_CUSTOMERS, [...customersWithAvatar]);
      commit(Mutations.SET_CUSTOMER_LOADING, false);
    } catch (error) {
      commit(Mutations.SET_CUSTOMER_LOADING, false);
      commit(Mutations.SET_CUSTOMER_ERROR, error);
    }
  },
  [Actions.SEARCH_CUSTOMERS]({ commit }, search) {
    commit(Mutations.SET_CUSTOMERS_SEARCH, search);
  },
  [Actions.CUSTOMER_SAVE_SELECTED]({ commit }, selected) {
    commit(Mutations.SET_CUSTOMER_SELECTED, selected);
  },
  [Actions.UPDATE_CUSTOMER]: async (
    { commit, dispatch, rootState },
    { id, changes, original }
  ) => {
    await Helpers.saveCustomer(id, changes);
    const user = getAuth().currentUser;
    await dispatch(Actions.LOG_EVENT, {
      user: user.email,
      action: LOG_ACTIONS.UPDATE_CUSTOMER,
      module: MODULE.COMMERCIAL,
      target: JSON.stringify({ name: changes.pool_name, id }),
      changes: JSON.stringify(objectCompare(original, changes)),
      datePerformed: serverTimestamp()
    });
    let userState = rootState.auth.users;
    if (userState.length === 0) {
      await dispatch(Actions.GET_ALL_USERS);
      userState = rootState.auth.users;
    }
    commit(Mutations.SET_UPDATED_CUSTOMER, {
      avatar: loadAvatar(userState, [changes], ["email"], ["created_by"])[0]
        .avatar,
      data: changes,
      id: id
    });
  },
  [Actions.DELETE_CUSTOMER]: async ({ commit, dispatch }, { id, name }) => {
    await deleteDoc(doc(Firebase.db, firestoreCollection.commercial, id));
    const user = getAuth().currentUser;
    await dispatch(Actions.LOG_EVENT, {
      user: user.email,
      action: LOG_ACTIONS.DELETE_CUSTOMER,
      module: MODULE.COMMERCIAL,
      target: JSON.stringify({ name, id }),
      datePerformed: serverTimestamp()
    });
    commit(Mutations.REMOVE_DELETED_CUSTOMER, id);
  },
  [Actions.SORT_ORDER_CUSTOMER]({ commit }, order) {
    commit(Mutations.SET_SORT_ORDER, order);
  },
  async [Actions.SEND_CUSTOMER_REPORT_CONDITION](
    { commit, dispatch },
    { refId, sendTo, sendToCc, currentUser }
  ) {
    try {
      commit(
        Mutations.SET_CUSTOMER_LOADING_MESSAGE,
        "Sending Customer Report Condition"
      );
      const { success } = await sendCustomerConditionReport({
        refId,
        email: sendTo,
        cc: sendToCc,
        currentUser: currentUser
      });
      const user = getAuth().currentUser;
      await dispatch(Actions.LOG_EVENT, {
        user: user.email,
        action: LOG_ACTIONS.SEND_CUSTOMER_REPORT_CONDITION,
        module: MODULE.COMMERCIAL,
        target: JSON.stringify({
          reference: refId,
          email: sendTo,
          cc: sendToCc
        }),
        datePerformed: serverTimestamp()
      });
      if (success) {
        return { success: true };
      }
    } catch (error) {
      commit(Mutations.SET_CUSTOMER_ERROR, error);
      return { success: false };
    }
  },
  [Actions.ADD_CONDITION_REPORT_TIMESTAMP]: async (
    { commit },
    { id, customer }
  ) => {
    await Helpers.saveCustomer(id, customer);
    commit(Mutations.SET_UPDATED_CUSTOMER_TIMESTAMP, {
      data: customer,
      id: id
    });
  }
};
