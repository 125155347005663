export default {
  allLogs(state) {
    return state.logs;
  },
  logsLoading(state) {
    return state.logsLoading;
  },
  logLoadingMessage(state) {
    return state.logs;
  },
  getAllLogCount(state) {
    return state.count;
  },
  getActivityLogError(state) {
    return state.logsError;
  }
};
