import * as Mutations from "../mutations.type";

export default {
  [Mutations.SET_PAYMENTS](state, data) {
    state.payments = data.payments;
    state.count = data.count;
  },
  [Mutations.SET_PAYMENT_LOADING](state, loading) {
    state.loading = loading;
  }
}