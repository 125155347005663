export default {
  errors: null,
  orders: [],
  loading: false,
  loadingMessage: "",
  filters: [],
  search: "",
  selected: [],
  count: 0,
  orderFromMongo: null,
  allOrdersFromMongo: []
};
