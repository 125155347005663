export default {
  error: null,
  branches: [],
  visitors: [],
  visitor: null,
  filters: [],
  personnel: [],
  searchString: "",
  sortBy: { sort: "timeIn", toggle: false },
  toDelete: "",
  loading: false,
  count: 0,
  visitorToday: []
};
