/**
 * 
 * @param {Array} userList The array of objects that contains all the avatar
 * @param {Array} targetList the list where we want to inject the avatars
 * @param {string} userIdentifier identifier to be used to identify the avatar in the userlist
 * @param {string} targetIdentifier identifier to be used to identify the avatar in the targetList
 * @returns targetList
 */
function loadAvatar(userList, targetList, userIdentifier, targetIdentifier) {

  return targetList.map(target => {
    const user = userList
      .find(user => 
        userIdentifier.reduce((selectedUser, key) => selectedUser[key], user) === 
        targetIdentifier.reduce((selectedTarget, key) => selectedTarget[key], target));
    return {
      ...target,
      avatar: user?.avatar ?? "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
    }
  })
}

export {
  loadAvatar
}