import * as Helpers from "@/store/helpers";

export default {
  allDealers(state) {
    return state.dealers;
  },
  dealerLoading(state) {
    return state.loading;
  },
  dealerSelected(state) {
    return state.selected;
  },
  dealerAttributes(state) {
    return state.attributes;
  },
  dealerFilters(state) {
    return state.filters;
  },
  filteredDealerSearch(state) {
    let list = state.dealers;
    list = Helpers.dealerSearchFilterHelper(state.search, list);
    list = Helpers.attributeFilterHelper(state, list);
    return list;
  },
  getSales(state) {
    return state.sales;
  },
  dealerAmazonEbayFilter(state) {
    return state.amazon_ebay_filter;
  }
};
