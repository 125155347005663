<template>
  <div class="maintenance">
    <div class="overlay"></div>
    <div class="content">
      <img
        src="https://images.ctfassets.net/ftcnv5up9dxv/1s6Hhy2KMbiFUHmZGxIFgg/44ad762cacb20d62283f7f35543a8cc1/maintenance__1_.png"
        alt="Maintenance"
        class="maintenance-image"
      />
      <h1>We'll be back soon!</h1>
      <p>
        Sorry for the inconvenience but we're performing some maintenance at the
        moment. We'll be back online shortly!
      </p>
      <div class="loader">
        <div class="loader-inner"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintenancePage",
  data() {
    return {};
  }
};
</script>

<style scoped>
.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?fit=crop&w=1920&h=1080&q=80")
    no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.content {
  position: relative;
  z-index: 1;
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 2s ease-in-out;
}

.maintenance-image {
  width: 150px;
  margin-bottom: 20px;
  animation: bounce 2s infinite;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  animation: fadeIn 2s ease-in-out;
}

p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  animation: fadeIn 2s ease-in-out;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.loader-inner {
  width: 100%;
  height: 100%;
  border: 16px solid rgba(255, 255, 255, 0.3);
  border-top: 16px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .loader {
    width: 80px;
    height: 80px;
  }

  .loader-inner {
    border-width: 12px;
  }
}
</style>
