import { spaces, client } from "@/common/ably";
import store from "../store/index";
import * as Mutations from "@/store/mutations.type";
import * as Actions from "@/store/actions.type";
import { ROOMS } from "@/store/constants/ably.constants";

export const initSpace = async (room) => {
  const currentUser = store.getters.currentUser;
  const space = await spaces.get(room);
  await space.enter({
    avatar: currentUser.avatar,
    name: currentUser.email.split(".")[0],
    email: currentUser.email
  });

  // Store all space members in store
  const allMembers = await space.members.getAll();
  store.commit(Mutations.LOAD_ALL_MEMBERS, {
    room: room,
    users: allMembers
      .filter((item) => item.isConnected)
      .map((item) => ({
        avatar: item.profileData.avatar,
        name: item.profileData.name,
        email: item.profileData.email
      }))
  });

  // Register Sub
  space.members.subscribe("update", async (member) => {
    const action = !member.isConnected
      ? Actions.LEAVE_ROOM
      : Actions.ENTER_ROOM;
    store.dispatch(action, {
      room,
      user: {
        name: member.profileData.name,
        avatar: member.profileData.avatar,
        email: member.profileData.email
      }
    });
  });

  return space;
};

export const enterSpace = async (space) => {
  const currentUser = store.getters.currentUser;
  await space.enter({
    avatar: currentUser.avatar,
    name: currentUser.email.split(".")[0],
    email: currentUser.email
  });
};

export const publishAnnouncementAbly = async (announcement) => {
  const channel = client.channels.get(`${ROOMS.APP}:announcements`);
  await channel.publish("announcement", announcement);
};

export const subscribeAnnouncement = async () => {
  const channel = client.channels.get(`${ROOMS.APP}:announcements`);
  channel.subscribe("announcement", ({ data }) => {
    store.commit(Mutations.UPDATE_NOTIFICATIONS, data);
    store.commit(Mutations.IS_NEW_NOTIFICATION, true);
  });
};
