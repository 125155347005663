import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_CUSTOMER_ERROR](state, error) {
    state.errors = error;
  },
  [Mutations.SET_CUSTOMER_LOADING](state, loading) {
    state.loading = loading;
  },
  [Mutations.SET_CUSTOMER_LOADING_MESSAGE](state, message) {
    state.loadingMessage = message;
  },
  [Mutations.SET_CUSTOMER](state, customer) {
    state.customers.unshift(customer);
  },
  [Mutations.SET_ALL_CUSTOMERS](state, customers) {
    state.customers = customers;
  },
  [Mutations.SET_CUSTOMERS_SEARCH](state, search) {
    state.search = search;
  },
  [Mutations.SET_CUSTOMER_SELECTED](state, selected) {
    state.selected = selected;
  },
  [Mutations.SET_UPDATED_CUSTOMER](state, customer) {
    state.customers = state.customers.map((cust) =>
      cust.id === customer.id
        ? { id: customer.id, data: customer.data, avatar: customer.avatar }
        : cust
    );
  },
  [Mutations.REMOVE_DELETED_CUSTOMER](state, id) {
    state.customers = state.customers.filter((c) => c.id !== id);
  },
  [Mutations.SET_SORT_ORDER](state, order) {
    state.order = order;
  },
  [Mutations.SET_UPDATED_CUSTOMER_TIMESTAMP](state, customer) {
    state.customers = state.customers.map((cust) =>
      cust.id === customer.id ? { ...cust, data: customer.data } : cust
    );
  }
};
