import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "vuex";
import * as Actions from "../store/actions.type";
import { ROLES } from "../store/constants/roles.constants";
const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/change_password",
    name: "change_password",
    meta: { requiresAuth: true },
    component: () => import("@/views/ChangePassword.vue")
  },
  {
    path: "/home",
    component: () => import("@/views/Home.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "dealers",
        name: "dealers",
        component: () => import("@/views/DealersTab")
      },
      {
        path: "online_store",
        name: "online_store",
        component: () => import("@/views/OnlineStore")
      },
      {
        path: "commercial",
        name: "commercial",
        component: () => import("@/views/CommercialTab")
      },
      {
        path: "admin",
        name: "admin",
        component: () => import("@/views/AdminTab")
      },
      {
        path: "order",
        name: "order",
        component: () => import("@/views/OrdersTab")
      },
      {
        path: "activity_logs",
        name: "activity_logs",
        component: () => import("@/views/ActivityLogsTab")
      },
      {
        path: "payments",
        name: "payments",
        component: () => import("@/views/Payments")
      },
      {
        path: "pos",
        name: "pos",
        component: () => import("@/views/PointOfSale")
      },
      {
        path: "visitor_logs",
        name: "visitor_logs",
        component: () => import("@/views/VisitorTab")
      },
      {
        path: "service_request",
        name: "service_request_dashboard",
        component: () => import("@/views/ServiceRequestApp.vue"),
        children: [
          {
            path: "drop_off",
            name: "drop_off",
            component: () => import("@/views/ServiceDropOffTab")
          },
          {
            path: "pick_up",
            name: "pick_up",
            component: () => import("@/views/ServicePickUpTab")
          }
        ]
      }
    ]
  },
  {
    path: "/_/auth/action",
    name: "firebase_action",
    component: () => import("@/views/FirebaseAction.vue")
  },
  {
    path: "/password-reset-request",
    name: "password_reset_request",
    component: () => import("@/views/PasswordResetRequest.vue")
  },
  {
    path: "/order/:orderId/:hashId",
    name: "download_orderId",
    component: () => import("@/views/OrderDetails.vue"),
    props: true
  },
  {
    path: "/visitor",
    name: "visitor_sign_in",
    component: () => import("@/views/VisitorSignIn.vue"),
    props: true
  },
  {
    path: "/service-request",
    name: "service_request",
    component: () => import("@/views/ServiceRequest.vue"),
    props: true
  },
  {
    path: "/dealer-register",
    name: "dealer_register",
    component: () => import("@/views/DealerRegister.vue"),
    props: true
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/NotFound.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// authenticate
router.beforeEach(async (to, from, next) => {
  const store = useStore();
  let user = store.state.auth.user;

  if (user?.email === undefined) {
    await store.dispatch(Actions.GET_CURRENT_USER);
    user = store.state.auth.user;
  }

  if (user.isFirstLogin) {
    if (to.name === "change_password") {
      return next();
    } else {
      return next("/change_password");
    }
  }

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (
    to.name !== "not-found" &&
    user?.role &&
    !ROLES[user?.role].allowed.includes(to.name) &&
    to.name !== "firebase_action"
  ) {
    let redirecTo = ROLES[user.role].allowed[0];
    if (
      window.location.pathname.split("/")[2] === "service_request" &&
      window.location.pathname.split("/")[3] !== "drop_off" &&
      window.location.pathname.split("/")[3] !== "pick_up"
    ) {
      redirecTo = ROLES[user.role].allowed.includes("drop_off")
        ? "drop_off"
        : ROLES[user.role].allowed.includes("pick_up")
        ? "pick_up"
        : ROLES[user.role].allowed[0];
    }
    return next({ name: redirecTo });
  }

  if (requiresAuth && user?.email === undefined) {
    return next("/");
  }

  next();
});

export default router;
