import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import { getAuth } from "firebase/auth";
import { deleteRegistrationRequest } from "@/store/helpers";
import {
  updateAccountRegisterRequest,
  createUserFromRegistration,
  getAllValidRegistrationCount,
  deleteUserDealerAccount
} from "@/common/salesAppApi";
import { serverTimestamp } from "firebase/firestore";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import MODULE from "@/store/constants/module.constants";
import {
  createAccountRegisterRequest,
  getAllRegistrations
} from "@/common/salesAppApi";

export default {
  [Actions.INITIAL_VALIDATE]({ commit }, payload) {
    if (payload === process.env.VUE_APP_REGISTER_SECRET) {
      commit(Mutations.SET_INITIAL_VALIDATION, { success: true, error: "" });
    } else {
      commit(Mutations.SET_INITIAL_VALIDATION, {
        success: false,
        error: "Invalid Secret"
      });
    }
  },
  async [Actions.CREATE_ACCOUNT_REGISTER_REQUEST]({ commit }, payload) {
    let data;
    try {
      const res = await createAccountRegisterRequest(payload);
      data = { success: true, data: res };
    } catch (error) {
      commit(Mutations.SET_REGISTRATION_ERROR, error);
      data = { success: false, error: "Something went wrong" };
    }
    return data;
  },
  async [Actions.GET_ALL_VALID_REGISTRATION_COUNT]({ commit }) {
    commit(Mutations.SET_REGISTRATION_LOADING, true);
    try {
      const { data } = await getAllValidRegistrationCount();
      commit(Mutations.SET_VALID_REGISTRATION_COUNT, data.count);
    } catch (error) {
      commit(Mutations.SET_VALID_REGISTRATION_COUNT, 0);
    }
    commit(Mutations.SET_REGISTRATION_LOADING, false);
  },
  async [Actions.GET_ALL_REGISTRATIONS]({ commit }, { page = 1, filter }) {
    try {
      const { data } = await getAllRegistrations({ page, filter });
      commit(Mutations.SET_ALL_REGISTRATIONS, data);
    } catch (error) {
      commit(Mutations.SET_REGISTRATION_ERROR, error);
    }
  },
  async [Actions.DELETE_REGISTRATION_REQUEST]({ commit, dispatch }, payload) {
    const currentUser = getAuth()?.currentUser;
    try {
      const data = await deleteRegistrationRequest(payload);
      const { dealerName, success, email } = data;
      if (success) {
        await dispatch(Actions.LOG_EVENT, {
          user: currentUser.email,
          action: LOG_ACTIONS.DELETE_REGISTRATION,
          module: MODULE.ADMIN,
          target: JSON.stringify({
            dealer_name: dealerName,
            email: email
          }),
          datePerformed: serverTimestamp()
        });
      }
    } catch (error) {
      commit(Mutations.SET_REGISTRATION_ERROR, error);
    }
    await dispatch(Actions.GET_ALL_VALID_REGISTRATION_COUNT);
  },
  async [Actions.UPDATE_REGISTRATION_REQUEST]({ commit, dispatch }, payload) {
    commit(Mutations.SET_REGISTRATION_ERROR, "");
    try {
      const res = await updateAccountRegisterRequest(payload);
      await dispatch(Actions.LOG_EVENT, {
        user: getAuth().currentUser.email,
        action: LOG_ACTIONS.UPDATE_REGISTRATION_REQUEST,
        module: MODULE.ADMIN,
        target: JSON.stringify({ name: payload.data.name }),
        datePerformed: serverTimestamp()
      });
      return { success: true, data: res };
    } catch (error) {
      commit(Mutations.SET_REGISTRATION_ERROR, error.response.data.message);
      return {
        success: false,
        error: error.response.data.message
          ? error.response.data.message
          : "Something went wrong"
      };
    }
  },
  async [Actions.CREATE_REGISTER_ACCOUNT]({ commit, dispatch }, payload) {
    try {
      const { data } = await createUserFromRegistration(payload);
      const userPayload = {
        ...payload,
        uid: data.uid,
        isFirstLogin: true
      };
      await dispatch(Actions.LOG_EVENT, {
        user: getAuth().currentUser.email,
        action: LOG_ACTIONS.CREATE_DEALER_USER,
        module: MODULE.ADMIN,
        target: JSON.stringify({ name: payload.name, email: payload.email }),
        datePerformed: serverTimestamp()
      });
      return { success: true, data, userPayload };
    } catch (error) {
      commit(Mutations.SET_REGISTRATION_ERROR, error.response.data.message);
      return {
        success: false,
        error: error.response.data.message
          ? error.response.data.message
          : "Something went wrong"
      };
    }
  },
  async [Actions.DELETE_DEALERUSER_ACCOUNT]({ commit, dispatch }, payload) {
    const currentUser = getAuth()?.currentUser;
    try {
      const { data } = await deleteUserDealerAccount({ id: payload });
      const { dealerName, success, email } = data;
      if (success) {
        await dispatch(Actions.LOG_EVENT, {
          user: currentUser.email,
          action: LOG_ACTIONS.DELETE_DEALERUSER_ACCOUNT,
          module: MODULE.ADMIN,
          target: JSON.stringify({
            dealer_name: dealerName,
            email: email
          }),
          datePerformed: serverTimestamp()
        });
      }
    } catch (error) {
      commit(Mutations.SET_REGISTRATION_ERROR, error);
    }
    await dispatch(Actions.GET_ALL_VALID_REGISTRATION_COUNT);
  }
};
