<template>
  <div
    v-if="options[type] && show"
    class="inset-0 fixed top-10 z-[100] m-auto left-0 right-0 flex justify-center items-start"
  >
    <div
      :class="`${
        type === 'success' ? 'bg-success' : 'bg-danger'
      } text-white p-4 rounded-md max-w-md flex items-center space-x-2`"
    >
      <component :is="options[type].icon" />
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import IconSolidCheck from "../../assets/icons/IconSolidCheck.vue";
import IconCross from "../../assets/icons/IconCross.vue";

export default {
  name: "SnackBar",
  data() {
    return {
      options: {
        success: {
          icon: markRaw(IconSolidCheck)
        },
        error: {
          icon: markRaw(IconCross)
        }
      },
      message: "",
      type: "",
      show: false
    };
  },
  methods: {
    showNotif({ message, type = "success" }) {
      this.type = type;
      this.show = true;
      this.message = message;
      setTimeout(() => {
        this.show = false;
        this.type = "";
        this.message = "";
      }, 5000);
    }
  }
};
</script>
