import { GET_ORDER_BY_ID_FROM_MONGO } from "@/common/mongo";
import moment from "moment";

export const getOrderById = async (id) => {
  const { data } = await GET_ORDER_BY_ID_FROM_MONGO.post("", id);
  const sortData = data.sort((a, b) => b.orderId - a.orderId);
  return sortData;
};

export const filterSales = async (year, id) => {
  let result = [];
  const getData = await getOrderById({ id: id });
  getData.map((item) => {
    if (
      item.meta.createdAt &&
      moment(item.meta.createdAt).format("D MMM YYYY").split(" ")[2] === year
    ) {
      result.push(item);
    }
  });
  return result;
};
