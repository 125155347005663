import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_INITIAL_VALIDATION](state, payload) {
    state.initialValidation = payload;
  },
  [Mutations.SET_REGISTRATION_ERROR](state, payload) {
    state.error = payload;
  },
  [Mutations.SET_ALL_REGISTRATIONS](state, payload) {
    state.registrations = payload.registrations;
    state.count = payload.count;
  },
  [Mutations.SET_REGISTRATION_LOADING](state, payload) {
    state.loading = payload;
  },
  [Mutations.SET_REGISTRATION_TO_DELETE](state, payload) {
    state.toDelete = payload;
  },
  [Mutations.SET_REGISTRATION_TO_EDIT](state, payload) {
    state.toEdit = payload;
  },
  [Mutations.SET_REGISTRATION_TO_REGISTER](state, payload) {
    state.toRegister = payload;
  },
  [Mutations.SET_DEALERUSER_TO_DELETE](state, payload) {
    state.dealerUserToDelete = payload;
  },
  [Mutations.SET_VALID_REGISTRATION_COUNT](state, payload) {
    state.validRegistrationCount = payload;
  }
};
