import {
  collection,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  deleteDoc
} from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
const firestoreCollection = getFirestoreCollectionConfig();

export const getAllBranchessWithAuth = async () => {
  const branches = await getDocs(collection(Firebase.db, "branches"));
  return {
    data: {
      branches: branches.empty
        ? parseBranchDoc(getDocs(collection(Firebase.db, "branches")))
        : parseBranchDoc(branches)
    }
  };
};

const parseBranchDoc = (snapshot) => {
  const branches = [];
  snapshot.forEach((doc) => {
    branches.push({
      id: doc.id,
      ...doc.data()
    });
  });
  return branches;
};

export const signOutVisitorWithAuth = async (visitorId) => {
  try {
    const docRef = doc(Firebase.db, firestoreCollection.visitor, visitorId);
    await updateDoc(docRef, { timeOut: serverTimestamp() });
    const docSnapshot = await getDoc(docRef);
    return {
      data: {
        doc: {
          id: docSnapshot.id,
          ...docSnapshot.data(),
          timeIn: {
            _seconds: docSnapshot.data().timeIn.seconds,
            _nanoseconds: docSnapshot.data().timeIn.nanoseconds
          },
          timeOut: docSnapshot.data().timeOut
            ? {
                _seconds: docSnapshot.data().timeOut.seconds,
                _nanoseconds: docSnapshot.data().timeOut.nanoseconds
              }
            : null
        }
      }
    };
  } catch (error) {
    console.error(error);
  }
};

export const deleteVisitorLog = async (visitorId) => {
  try {
    const docRef = doc(Firebase.db, firestoreCollection.visitor, visitorId);
    const snapshot = await getDoc(docRef);
    const visitorName = `${snapshot.data().firstName} ${
      snapshot.data().lastName
    }`;
    await deleteDoc(docRef);
    return { visitorName: visitorName, success: true };
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};
