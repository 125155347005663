import * as Firebase from "@/common/firebase.js";
import {
  collection,
  getDocs,
  serverTimestamp,
  orderBy,
  query
} from "firebase/firestore";
import Fuse from "fuse.js";

export const formatDate = (date) => {
  let newdate = new Date(date);
  const day = newdate.getDate();
  const month = newdate.getMonth() + 1;
  const year = newdate.getFullYear();
  return day + "/" + month + "/" + year;
};

export const PARSE_DATA_TO_PRIORITY = async (order) => {
  const body = [
    {
      RECORDTYPE: "1",
      REFERENCE: `${order.REFERENCE}`,
      CUSTDES: `${order.CUSTDES}`,
      DEALER: `${order.DEALER}`,
      PAYMENTNAME: "",
      MARKET_INFO: "No Info",
      TOTPRICE: "",
      ADDRESS: "",
      CITY: "",
      STATENAME: "",
      COUNTRYNAME: "",
      ZIP: "",
      PHONE: "",
      FAX: "",
      EMAIL: "",
      PARTNAME: "",
      TQUANT: "",
      PRICE: "",
      IVDATE: `${formatDate(order.IVDATE)}`,
      SITECODE: ""
    }
  ];
  const products = order.products.map(({ sku, qty, price }) => {
    return {
      RECORDTYPE: "2",
      REFERENCE: `${order.REFERENCE}`,
      CUSTDES: "",
      DEALER: "",
      PAYMENTNAME: "",
      MARKET_INFO: "",
      TOTPRICE: "",
      ADDRESS: "",
      CITY: "",
      STATENAME: "",
      COUNTRYNAME: "",
      ZIP: "",
      PHONE: "",
      FAX: "",
      EMAIL: "",
      PARTNAME: `${sku}`,
      TQUANT: `${qty}`,
      PRICE: `${((Number(price) * 100) / 110).toFixed(2)}`,
      IVDATE: `${formatDate(order.IVDATE)}`,
      SITECODE: ""
    };
  });
  await body.push.apply(body, products);
  const freight = order.freight.map(({ qty, price }) => {
    return {
      RECORDTYPE: "2",
      REFERENCE: `${order.REFERENCE}`,
      CUSTDES: "",
      DEALER: "",
      PAYMENTNAME: "",
      MARKET_INFO: "",
      TOTPRICE: "",
      ADDRESS: "",
      CITY: "",
      STATENAME: "",
      COUNTRYNAME: "",
      ZIP: "",
      PHONE: "",
      FAX: "",
      EMAIL: "",
      PARTNAME: "FR",
      TQUANT: `${qty}`,
      PRICE: `${((Number(price) * 100) / 110).toFixed(2)}`,
      IVDATE: `${formatDate(order.IVDATE)}`,
      SITECODE: ""
    };
  });
  await body.push.apply(body, freight);
  const tail = [
    {
      RECORDTYPE: "3",
      REFERENCE: `${order.REFERENCE}`,
      CUSTDES: "",
      DEALER: "",
      PAYMENTNAME: "",
      MARKET_INFO: "",
      TOTPRICE: "",
      ADDRESS: `${order.ADDRESS}`,
      CITY: `${order.CITY}`,
      STATENAME: `${order.STATENAME}`,
      COUNTRYNAME: "Australia",
      ZIP: `${order.ZIP}`,
      PHONE: `${order.PHONE}`,
      FAX: "",
      EMAIL: `${order.EMAIL}`,
      PARTNAME: "",
      TQUANT: "",
      PRICE: "",
      IVDATE: `${formatDate(order.IVDATE)}`,
      SITECODE: ""
    },
    {
      RECORDTYPE: "4",
      REFERENCE: `${order.REFERENCE}`,
      CUSTDES: "",
      DEALER: "",
      PAYMENTNAME: "10",
      MARKET_INFO: "",
      TOTPRICE: `${order.TOTPRICE}`,
      ADDRESS: "",
      CITY: "",
      STATENAME: "",
      COUNTRYNAME: "",
      ZIP: "",
      PHONE: "",
      FAX: "",
      EMAIL: "",
      PARTNAME: "",
      TQUANT: "",
      PRICE: "",
      IVDATE: `${formatDate(order.IVDATE)}`,
      SITECODE: ""
    }
  ];
  await body.push.apply(body, tail);
  return body;
};

export const PARSE_DATA_TO_FIREBASE = (order, payload) => {
  const data = {
    reference: order.REFERENCE,
    name: order.CUSTDES,
    dealer: order.DEALER,
    total: order.TOTPRICE,
    address: order.ADDRESS,
    city: order.CITY,
    state: order.STATENAME,
    postcode: order.ZIP,
    phone: order.PHONE,
    email: order.EMAIL,
    url: order.URL,
    store: order.STORE,
    products: order.products,
    freight: order.freight,
    data: payload,
    timestamp: serverTimestamp(),
    tracking: order.tracking,
    date: order.IVDATE,
    dealer_name: order.dealer_name,
    user: Firebase.auth.currentUser.email,
    year: order.IVDATE.split("-")[0],
    dealer_email: order.dealer_email,
    status: order.status
  };
  return data;
};

export const getAllOrders = async (dbCollection) => {
  const order = await getDocs(
    query(
      collection(Firebase.db, `${dbCollection}`),
      orderBy("timestamp", "desc")
    )
  );

  return order.empty
    ? parseOrderDoc(getDocs(collection(Firebase.db, `${dbCollection}`)))
    : parseOrderDoc(order);
};

export const parseOrderDoc = (snapshot) => {
  const orders = [];
  snapshot.forEach((doc) => {
    orders.push({
      id: doc.id,
      store: doc.data().store,
      data: doc.data()
    });
  });
  return orders;
};

export const orderSearchFilterHelper = (search, list) => {
  let filter = list;
  if (search !== "" && search !== null) {
    filter = new Fuse(list, fuseConfigOrder)
      .search(search)
      .map((res) => res.item);
  }
  return filter;
};

export const fuseConfigOrder = {
  keys: [
    {
      name: "data.reference",
      weight: 0.9
    },
    {
      name: "data.name",
      weight: 0.8
    },
    {
      name: "data.phone",
      weight: 0.8
    },
    {
      name: "data.dealer",
      weight: 2
    }
  ],
  includeScore: true,
  shouldSort: true,
  threshold: 0.15,
  findAllMatches: true
};

export const orderAttributeFilterHelper = (state, list) => {
  let filter = list;
  if (state.filters.length > 0) {
    const storeFilters = state.filters.filter(([field]) => field === "store");
    const statusFilters = state.filters.filter(([field]) => field === "status");
    const productFilters = state.filters.filter(
      ([field]) => field === "products"
    );
    const freightFilters = state.filters.filter(
      ([field]) => field === "freight"
    );
    const userFilters = state.filters.filter(([field]) => field === "user");
    const stateFilters = state.filters.filter(([field]) => field === "state");
    const trackingFilters = state.filters.filter(
      ([field]) => field === "tracking"
    );
    const yearFilters = state.filters.filter(([field]) => field === "date");
    const monthFilters = state.filters.filter(([field]) => field === "month");
    if (storeFilters.length > 0) {
      filter = storeFilters
        .map(([field, value]) => filter.filter((p) => p[field] === value))
        .flat();
    }
    if (stateFilters.length > 0) {
      filter = stateFilters
        .map(([field, value]) => filter.filter((p) => p.data[field] === value))
        .flat();
    }
    if (productFilters.length > 0) {
      filter = productFilters
        .map(([field, value]) =>
          filter.filter((p) => p.data[field].find((i) => i.name === value))
        )
        .flat();
    }
    if (freightFilters.length > 0) {
      filter = freightFilters
        .map(([field, value]) =>
          filter.filter((p) => p.data[field].find((i) => i.name === value))
        )
        .flat();
    }
    if (userFilters.length > 0) {
      filter = userFilters
        .map(([field, value]) => filter.filter((p) => p.data[field] === value))
        .flat();
    }
    if (trackingFilters.length > 0) {
      filter = trackingFilters
        .map(([field, value]) => filter.filter((p) => p.data[field] === value))
        .flat();
    }
    if (yearFilters.length > 0) {
      filter = yearFilters
        .map(([field, value]) =>
          filter.filter((p) => p.data[field].split("-")[0] === value)
        )
        .flat();
    }
    if (monthFilters.length > 0) {
      filter = monthFilters
        .map((value) =>
          filter.filter((p) => p.data.date.split("-")[1] === value[1])
        )
        .flat();
    }
    if (statusFilters.length > 0) {
      filter = statusFilters
        .map(([field, value]) => filter.filter((p) => p.data[field] === value))
        .flat();
    }
  }
  return filter;
};

export const orderSelectableId = (store, id) => {
  return `${store}:${id}`;
};

export const parseOrdersToCsvFormatFull = (orders) => {
  const headings = [
    "REFERENCE #",
    "STORE",
    "DATE",
    "CUSTOMER NAME",
    "ADDRESS LINE 1",
    "SUBURB",
    "STATE",
    "POSTCODE",
    "PHONE",
    "INVOICE",
    "ASSIGNED DEALER",
    "PRODUCT",
    "TOTAL PRICE",
    "TRACKING",
    "SUBMITTED BY"
  ];
  return [
    headings,
    ...orders.map((order) => [
      order.data.reference,
      order.data.store,
      order.data.date,
      order.data.name,
      order.data.address,
      order.data.city,
      order.data.state,
      order.data.postcode,
      order.data.phone,
      order.data.url,
      order.data.dealer_name,
      order.data.products.map((f) => f.name).join(", "),
      order.data.total,
      order.data.tracking,
      order.data.user
    ])
  ];
};

export const parseOrdersToCsvFormatSelected = (orders) => {
  const headings = [
    "REFERENCE #",
    "STORE",
    "DATE",
    "CUSTOMER NAME",
    "ADDRESS LINE 1",
    "SUBURB",
    "STATE",
    "POSTCODE",
    "PHONE",
    "INVOICE",
    "ASSIGNED DEALER",
    "PRODUCT",
    "TOTAL PRICE",
    "TRACKING",
    "STATUS",
    "NOTES",
    "SUBMITTED BY"
  ];
  return [
    headings,
    ...orders.map((order) => [
      order.reference,
      order.store,
      order.date,
      order.name,
      order.address,
      order.city,
      order.state,
      order.postcode,
      order.phone,
      order.url,
      order.dealer_name,
      order.products.map((f) => f.name).join(", "),
      order.total,
      order.tracking,
      order.status,
      order.notes,
      order.user
    ])
  ];
};

export const instantiateOrderData = (state) => {
  if (!state.orderId) {
    return (state.order = null);
  }
  const [store, id] = state.orderId.split(":");
  state.order = Object.assign(
    {},
    state.allOrders.find(
      (p) => String(p.id) === id && String(p.store) === store
    ).data
  );
  return state.order;
};
