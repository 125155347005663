import * as Actions from "../actions.type";
import * as Mutations from "../mutations.type";
import {getPayments} from "../../common/salesAppApi";

export default {
  async [Actions.GET_PAYMENTS]({commit}, { page = 1, filter }) {
    commit(Mutations.SET_PAYMENT_LOADING, true);
    const { data } = await getPayments({page, filter});
    commit(Mutations.SET_PAYMENTS, data);
    commit(Mutations.SET_PAYMENT_LOADING, false);
  }
}