import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.UPDATE_NOTIFICATIONS](state, payload) {
    const isThereAnnouncementExisting = state.notifications.filter(
      (notification) => {
        return notification.type === "announcement";
      }
    );
    const newHasAnnouncement = payload.filter((notification) => {
      return notification.type === "announcement";
    });
    if (isThereAnnouncementExisting.length && newHasAnnouncement.length) {
      const oldValue = JSON.parse(JSON.stringify(state.notifications));
      state.notifications = [
        ...state.notifications.filter(
          (notification) => notification.type !== "announcement"
        )
      ];
      setTimeout(() => {
        state.notifications = [
          ...oldValue.filter(
            (notification) => notification.type !== "announcement"
          ),
          ...payload
        ];
      }, 1000);
    } else {
      state.notifications = payload.length
        ? [...state.notifications, ...payload]
        : state.notifications;
    }
  },
  [Mutations.REMOVE_NOTIFICATION](state, payload) {
    const filteredNotifications = state.notifications.filter((notification) => {
      return notification.id !== payload;
    });
    state.notifications = filteredNotifications;
  },
  [Mutations.IS_NEW_NOTIFICATION](state, payload) {
    state.isNewNotification = payload;
  }
};
