export default {
  getPayments(state) {
    return state.payments;
  },
  getAllPaymentCount(state) {
    return state.count;
  },
  paymentLoading(state) {
    return state.loading;
  }
}