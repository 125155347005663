import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const wooCommerceClient = new WooCommerceRestApi({
  url: "https://mydolphin.com.au",
  consumerKey: process.env.VUE_APP_CONSUMER_KEY,
  consumerSecret: process.env.VUE_APP_CONSUMER_SECRET,
  axiosConfig: {
    headers: { "Content-Type": "application/json;charset=UTF-8" }
  }
});

export default wooCommerceClient;
