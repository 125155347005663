import { customerFormData } from "@/store/constants/pos.constants";
export default {
  allCoupons: [],
  products: [],
  loading: false,
  errors: "",
  cart: {
    products: [],
    customerInfo: JSON.parse(JSON.stringify(customerFormData)),
    couponCode: ""
  }
};
