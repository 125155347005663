export const DEALER_DEFAULT_STATE = {
  priorityId: "",
  affiliateId: "",
  address: "",
  contactName: "",
  custID: "",
  email: "",
  flag: [],
  logo: "https://images.ctfassets.net/ftcnv5up9dxv/4vxytkH8ppvbvETSTCXmyR/02975ee9e09a1d9f75d8e9d061af1f58/Authorised-dealer.png",
  minisite: "",
  mobile: "",
  name: "",
  phone: "",
  line1: "",
  line2: "",
  city: "",
  postCode: "",
  country: "Australia",
  xCoordinates: "",
  yCoordinates: "",
  rep: "",
  sfId: "",
  state: "",
  placeId: "",
  fbId: "",
  igId: "",
  status: "active",
  type: [],
  website: "",
  completed: [],
  mongoId: "",
  data: {
    completed: []
  },
  active_sprout_url: [],
  best_practice_training_url: [],
  brochures_up_to_date_url: [],
  clean_up_to_date_pos_url: [],
  competitive_activity_url: [],
  conference_contribution_url: [],
  current_active_pos_url: [],
  customised_digital_ring_fencing_url: [],
  dealer_mpp_training_url: [],
  demo_training_url: [],
  diy_dealer_training_url: [],
  dolphin_sales_url: [],
  ecomm_embedded_url: [],
  elite_bulletins_url: [],
  elite_facebook_url: [],
  glass_media_training_url: [],
  glass_sales_url: [],
  incentive_url: [],
  lam_opportunities_url: [],
  market_current_promo_url: [],
  mineral_sales_url: [],
  mini_site_active_url: [],
  ms_pro_training_url: [],
  ms_sales_url: [],
  mtau_conference_url: [],
  ozone_sales_url: [],
  ozone_swc_training_url: [],
  point_of_sales_items_url: [],
  swc_sales_url: [],
  top_line_displayed_url: [],
  wt_displays_setup_url: []
};

export const DEFAULT_ORDER_STATE = {
  RECORDTYPE: "",
  REFERENCE: "",
  CUSTDES: "",
  DEALER: "",
  PAYMENTNAME: "",
  MARKET_INFO: "",
  TOTPRICE: "",
  ADDRESS: "",
  CITY: "",
  STATENAME: "",
  COUNTRYNAME: "",
  ZIP: "",
  PHONE: "",
  FAX: "",
  EMAIL: "",
  PARTNAME: "",
  TQUANT: "",
  PRICE: "",
  IVDATE: "",
  SITECODE: "",
  URL: "",
  STORE: "ebay",
  products: [],
  freight: [],
  tracking: false,
  dealer_name: "",
  user: "",
  year: "",
  dealer_email: [],
  status: "processing"
};

export const DEFAULT_CUSTOMER_STATE = {
  date: "",
  pool_name: "",
  managed_by: "",
  contact_name: "",
  contact_phone: "",
  contact_email: "",
  robot_type: [],
  pool_covers: [],
  lane_ropes: [],
  starting_blocks: [],
  dolphin_lift: [],
  poolies_pal: [],
  disability_hoist: [],
  wet_deck: [],
  robot_type_image: [],
  pool_covers_image: [],
  lane_ropes_image: [],
  starting_blocks_image: [],
  dolphin_lift_image: [],
  poolies_pal_image: [],
  disability_hoist_image: [],
  wet_deck_image: [],
  chemicals: [],
  plant_room: [],
  general_comment: "",
  overview_confirmation: ""
};
