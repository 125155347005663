import * as Mutations from "@/store/mutations.type";
import { customerFormData } from "../constants/pos.constants";
export default {
  [Mutations.SET_PRODUCTS](state, data) {
    state.products = data;
  },
  [Mutations.SET_PRODUCT_LOADING](state, data) {
    state.loading = data;
  },
  [Mutations.SET_CART_ITEMS](state, data) {
    state.cart.products = [...data];
  },
  [Mutations.RESET_CART](state) {
    state.cart.products = [];
    state.cart.customerInfo = JSON.parse(JSON.stringify(customerFormData));
    state.cart.couponCode = "";
  },
  [Mutations.SET_CART_CUSTOMER](state, payload) {
    state.cart.customerInfo = payload;
  },
  [Mutations.SET_POS_ERROR](state, payload) {
    state.errors = payload;
  },
  [Mutations.SET_ALL_COUPONS](state, payload) {
    state.allCoupons = payload;
  },
  [Mutations.SET_COUPON](state, payload) {
    state.cart.couponCode = payload;
  }
};
