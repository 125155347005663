export default {
  getAllRooms(state) {
    return state.rooms
  },
  getOthersInRoom(state) {
    return function (room, currentUser) {
      if (state.rooms[room]) {
        return state.rooms[room]
          // Remove duplicate users in a single room
          .reduce((acc, user) => {
            const isUserExists = acc.find(u => u.email === user.email);
            if (!isUserExists) {
              acc.push(user);
            }
            return acc;
          }, [])
          .filter(user => user.email !== currentUser);
      }

      return [];
    }
  }
}