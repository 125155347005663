import { uploadFileByAPI } from "@/common/salesAppApi";
export const handleFileChangeAPI = async (e) => {
  try {
    const { url } = await uploadFileByAPI(
      `${e.target.files[0].name.replace(/\s+/g, "-").toLowerCase()}`,
      e.target.files[0]
    );
    return url;
  } catch (error) {
    console.log(error);
  }
};
