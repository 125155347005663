export const ROOMS = {
  APP: "sales-app",
  DEALERS: "sales-app-dealers",
  COMMERCIAL: "sales-app-commercial",
  ONLINE: "sales-app-online",
  ADMIN: "sales-app-admin",
  ADMINDEALER: "sales-app-admin-dealer",
  ORDER: "sales-app-order",
  ACTIVITY_LOGS: "sales-app-activity-logs",
  PAYMENTS: "sales-app-payments",
  POS: "sales-app-pos",
  VISITOR: "sales-app-visitor",
  REGISTRATION: "sales-app-registration",
  SERVICEREQUESTPICKUP: "sales-app-servicerequestpickup",
  SERVICEREQUESTDROPOFF: "sales-app-servicerequestdropoff"
};
