export const POSNAV = [
  {
    title: "Products",
    value: "products",
    svg: "M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
  },
  {
    title: "Info",
    value: "info",
    svg: "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
  },
  {
    title: "Payment",
    value: "payment",
    svg: "M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
  }
];
export const customerFormData = {
  first_name: "",
  last_name: "",
  company_name: "",
  contact_phone: "",
  email: "",
  address: {
    address: "",
    addressLine: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: ""
  },
  deliveryAddress: {
    address: "",
    addressLine: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: ""
  },
  differentDeliveryAddress: false,
  deliveryNotes: ""
};
